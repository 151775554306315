import { useRef } from 'react';
import type {
  DecoratedChecklistItem,
  UpdateChecklistItemRequest,
} from '@witmetrics/api-client';
import { twMerge } from 'tailwind-merge';
import ListItem from '@mui/material/ListItem';
import CheckBox from '@/components/CheckBox';
import OptionsIcon from '@/icons/OptionsIcon';
import ItemInput from '../ItemInput';
import { Draggable } from 'react-smooth-dnd';
import {
  deleteChecklistItem as dispatchDeleteChecklistItem,
  updateChecklistItem as dispatchUpdateChecklistItem,
} from '@/store/slices/checklistItemsSlice';
import { updateChecklist as dispatchUpdateChecklist } from '@/store/slices/checklistsSlice';
import { useChecklist, useDispatch } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import { mapToID } from '@/utils/arrays';
import {
  type CheckBoxChangeEvent,
  classes,
  removeItem,
  styles,
  updateItem,
} from './utils';

export type ChecklistItemProps = {
  draggable?: boolean;
  item: DecoratedChecklistItem;
};

export default function ChecklistItem({
  draggable = false,
  item,
}: ChecklistItemProps) {
  const menuToggleRef = useRef(null);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const checklist = useChecklist(item.checklistID);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);
  const [isUpdating, toggleUpdating] = useToggle(false);
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleUpdateItem = async (updates: UpdateChecklistItemRequest) => {
    try {
      toggleUpdating(true);
      const updatedItem = await updateItem(item.id, updates);
      dispatch(dispatchUpdateChecklistItem(updatedItem));
      toggleUpdating(false);
      toggleEditing(false);
    } catch (err) {
      toggleUpdating(false);
      onApiError(err, 'Error updating checklist item');
    }
  };

  const handleToggleChecked: CheckBoxChangeEvent = ({ isComplete }) => {
    return handleUpdateItem({ isComplete });
  };

  const handleUpdateDescription = (description: string) => {
    return handleUpdateItem({ description });
  };

  const handleEditItem = () => {
    toggleMenu(false);
    toggleEditing(true);
  };

  const handleRemoveItem = async () => {
    try {
      toggleMenu(false);
      toggleDeleting(true);
      const update = await removeItem(item.id);
      if (checklist) {
        const items = checklist.items.filter((i) => i.id !== item.id);
        const itemOrder = mapToID(items);
        dispatch(
          dispatchUpdateChecklist({ ...update.checklist, items, itemOrder })
        );
      }
      dispatch(dispatchDeleteChecklistItem({ itemID: item.id }));
      toggleUpdating(false);
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error removing checklist item');
    }
  };

  const renderContent = () => {
    return (
      <ListItem sx={styles.listItem} className={classes.listItem}>
        <div className={classes.content}>
          {!isEditing && (
            <div className={classes.checkboxWrapper}>
              <CheckBox
                name="isComplete"
                isUpdating={isUpdating}
                isChecked={item.isComplete}
                onChange={handleToggleChecked}
              />
            </div>
          )}
          {isEditing ? (
            <ItemInput
              isSaving={isUpdating}
              itemID={item.id}
              onSave={handleUpdateDescription}
              onCancel={() => toggleEditing(false)}
            />
          ) : (
            <div className={classes.contentWrapper}>
              <div
                className={twMerge(
                  classes.description,
                  item.isComplete && classes.complete
                )}
                onClick={() => toggleEditing(true)}>
                {item.description}
              </div>
              <OptionsIcon
                ref={menuToggleRef}
                className={classes.optionsButton}
                onClick={() => toggleMenu()}
              />
              <DefaultOptionsMenu
                open={isMenuOpen}
                anchorTo={menuToggleRef.current}
                onEdit={handleEditItem}
                onDelete={handleRemoveItem}
                onClose={() => toggleMenu()}
              />
            </div>
          )}
        </div>
      </ListItem>
    );
  };

  return draggable && !isEditing ? (
    /* @ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93 */
    <Draggable>{renderContent()}</Draggable>
  ) : (
    renderContent()
  );
}
