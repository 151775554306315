export const classes = {
  wrapper: 'w-full flex justify-start items-start pt-6',
  textWrapper: 'flex flex-col items-center justify-start flex-grow',
  header: 'font-bold text-xl text-grey-8',
  status: 'mb-2',
  icon: 'text-3xl mx-3 text-grey-7 cursor-pointer',
  person: 'mr-2',
  button:
    'cursor-pointer text-grey-8 flex items-center justify-between hover:bg-grey-3 rounded-xl px-4 py-2',
};

export const styles = {
  collapseIcon: (isCollapsed?: boolean) => ({
    transform: `rotateY(${isCollapsed ? '180deg' : '0deg'})`,
    transition: 'transform 0.15s',
  }),
};
