import { isPast, isToday, isTomorrow } from '@/utils/time';

export const classes = {
  wrapper:
    'rounded-lg py-1 px-3 flex items-center justify-between text-sm font-medium',
  timeIcon: 'text-lg',
  closeIcon: 'text-lg ml-3 close-icon',
  content: 'ml-2',
  pastDue: 'bg-red-1 text-red-4',
  dueToday: 'bg-orange-1 text-orange-4',
  dueTomorrow: 'bg-yellow-1 text-yellow-4',
  dueEventually: 'bg-grey-3 text-grey-7',
};

export const styles = {
  button: {
    '& .close-icon': {
      display: 'none',
    },
    '&:hover .close-icon': {
      display: 'block',
    },
  },
};

export function getIconColor(value: string | null | undefined) {
  if (!value) return 'grey-6';
  if (isToday(value)) return 'orange-4';
  if (isPast(value)) return 'red-4';
  if (isTomorrow(value)) return 'yellow-3';
  return 'grey-6';
}

export function getDueDateClasses(value: string | null | undefined) {
  if (!value) return classes.dueEventually;
  if (isToday(value)) return classes.dueToday;
  if (isPast(value)) return classes.pastDue;
  if (isTomorrow(value)) return classes.dueTomorrow;
  return classes.dueEventually;
}
