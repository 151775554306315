import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export type SectionProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export default function Section({ className = '', ...props }: SectionProps) {
  return <div className={twMerge(classes.section, className)} {...props} />;
}
