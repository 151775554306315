import { PLATFORMS } from '../utils';

const { ACCOUNTS } = PLATFORMS;

export const classes = {
  wrapper: 'flex items-center px-3 py-2 cursor-pointer',
  label: 'select-none text-grey-8 font-normal text-base ml-2 leading-tight',
  button: 'text-sm text-grey-7 hover:underline',
};

export const ACCOUNT_SETTINGS_LINK = `${ACCOUNTS}/dashboard/profile/`;
export const PRACTICE_SETTINGS_LINK = `${ACCOUNTS}/dashboard/practices/`;
export const SUPPORT_LINK =
  'https://witmetrics.atlassian.net/servicedesk/customer/portals';
