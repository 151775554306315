import { theme } from '@/utils/theme';

export const classes = {
  wrapper: 'flex',
};

export const styles = {
  calendar: {
    '& .MuiPickersYear-yearButton.Mui-selected': {
      backgroundColor: theme.palette['purple-2'].main,
    },
    '& .MuiPickersDay-today:not(.Mui-selected)': {
      border: `1px solid ${theme.palette['grey-6'].main}`,
    },
    '& .MuiPickersDay-root:focus.Mui-selected, & .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:hover':
      {
        backgroundColor: theme.palette['purple-2'].main,
      },
  },
};
