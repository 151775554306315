import { differenceInSeconds, parseISO, startOfDay } from 'date-fns';

export const classes = {
  wrapper: 'absolute py-0 pr-2 pl-1',
  event: 'w-full h-full',
};

export const styles = {
  wrapper: (height: number, offset: number, offsetTop: string) => ({
    height: `${height}px`,
    top: `calc(${offset}px + ${offsetTop})`,
  }),
};

export function getOffset(dueAt: string, hourHeight: number) {
  const eventDate = parseISO(dueAt);
  const diff = differenceInSeconds(eventDate, startOfDay(eventDate));
  return hourHeight * (diff / (60 * 60)); // Convert from seconds to hours
}
