import { type KeyboardEvent, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@/icons/SearchIcon';
import CloseIcon from '@/icons/CloseIcon';
import IconButton from '@mui/material/IconButton';
import { classes, styles } from './utils';

export type SearchFieldProps = {
  className?: string;
  label?: string;
  value: string;
  onChange: (search: string) => void;
};

export default function SearchField({
  className = '',
  label = 'Filter...',
  value,
  onChange,
}: SearchFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Clear the search without closing the dialog
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onChange('');
      inputRef.current?.blur();
    }
  };

  return (
    <FormControl
      variant="standard"
      sx={styles.formControl}
      className={twMerge(classes.formControl, className)}>
      <InputLabel htmlFor="user-search">{label}</InputLabel>
      <Input
        inputRef={inputRef}
        autoFocus
        autoComplete="off"
        id="user-search"
        type="text"
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            {value.length > 0 ? (
              <IconButton
                className={classes.iconButton}
                onClick={() => onChange('')}>
                <CloseIcon />
              </IconButton>
            ) : (
              <SearchIcon className={classes.icon} />
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
