import { API } from '@/api';
import { mapToKey } from '@/utils/arrays';

export const classes = {
  dialog: 'w-[98vw] max-w-[650px] min-w-[350px]',
  body: 'w-full h-[70vh] max-h-[750px] min-h-[100px] pt-2 pb-12 overflow-auto',
  description: 'w-full pt-2 overflow-auto text-center text-grey-7',
  paragraph: 'mb-4 text-base text-grey-8',
  divider: 'my-4',
  selectorWrapper: 'w-full max-w-[500px] mx-auto',
};

export async function fetchConversationUsers(conversationID: number) {
  const users = await API.Conversations.fetchConversationUsers(conversationID, {
    filters: [],
    pagination: {
      page: 1,
      pageSize: 100, // NOTE: Should be large enough to fetch them all
    },
  });
  return mapToKey(users.results, 'user');
}
