import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import EmptyMessage from '../../EmptyMessage';
import Card from '@/components/Card';
import { useAppState } from '@/providers/AppStateProvider';
import ConversationsList from '@/components/ConversationsList';
import { useConversationsFromUnisonProjectID } from '@/store/useStore';
import { sortByUpdatedAt } from '@/utils/arrays';
import { classes } from './utils';

export type ConversationsSectionProps = {
  isFetchingData: boolean;
  onSelectConversation: (conversationID: number) => void;
};

export default function ConversationsSection({
  isFetchingData,
  onSelectConversation,
}: ConversationsSectionProps) {
  const { activeProjectID } = useAppState();
  const conversations = useConversationsFromUnisonProjectID(activeProjectID);

  const renderConversations = () => {
    if (conversations.length === 0) {
      return <EmptyMessage>No conversations for current project.</EmptyMessage>;
    }
    return (
      <Card className={classes.card}>
        <ConversationsList
          className={classes.list}
          isFetchingData={isFetchingData}
          conversations={sortByUpdatedAt(
            conversations?.map((c) => ({ ...c, unisonProjects: [] })) || []
          )}
          onSelect={onSelectConversation}
        />
      </Card>
    );
  };

  if (isFetchingData) return null;

  return (
    <Section>
      <SectionTitle
        title="Conversations"
        newLabel="New conversation"
        onAddNew={() => null /*TODO*/}
      />
      {renderConversations()}
    </Section>
  );
}
