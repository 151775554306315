import { useEffect, useState } from 'react';
import { type DecoratedChecklist } from '@witmetrics/api-client';
import { sortByCreatedAt } from '@/utils/arrays';
import {
  useActiveAccount,
  useChecklistsFromUnisonProjectID,
} from '@/store/useStore';
import { Tab } from '@/components/TabsBlock';
import ChecklistCard from './ChecklistCard';
import NewChecklist from './NewChecklist';
import { useAppState } from '@/providers/AppStateProvider';
import { classes, fetchChecklistTemplates } from './utils';

export type ChecklistsTabProps = {
  isFetchingData: boolean;
  name: string;
  label: string;
};

export default function ChecklistsTab({
  isFetchingData,
  name,
  label,
}: ChecklistsTabProps) {
  const activeAccount = useActiveAccount();
  const { onApiError, activeProjectID } = useAppState();
  const checklists = useChecklistsFromUnisonProjectID(activeProjectID);
  const [checklistTemplates, setChecklistTemplates] = useState<
    DecoratedChecklist[]
  >([]);

  useEffect(() => {
    if (activeAccount) fetchData();
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      const templates = await fetchChecklistTemplates(activeAccount!.accountID);
      setChecklistTemplates(templates); // Keep them local
    } catch (err) {
      onApiError(err, 'Error fetching checklist templates', () => fetchData());
    }
  };

  if (!activeProjectID) return null;

  return (
    <Tab name={name} label={label}>
      <div className={classes.wrapper}>
        {sortByCreatedAt(checklists).map((checklist) => (
          <ChecklistCard
            key={checklist.id}
            isFetchingData={isFetchingData}
            checklistID={checklist.id}
          />
        ))}
        <div>
          <NewChecklist
            projectID={activeProjectID}
            checklistTemplates={checklistTemplates}
          />
        </div>
      </div>
    </Tab>
  );
}
