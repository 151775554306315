import { type CSSProperties } from 'react';
import { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import { twMerge } from 'tailwind-merge';
import Event from '@/components/Event';
import { classes, getOffset, styles } from './utils';

export type AgendaEventProps = {
  style?: CSSProperties;
  className?: string;
  offsetTop?: string;
  unisonProjectTask: DecoratedUnisonProjectTask;
  hourHeight: number;
};

export default function AgendaEvent({
  style = {},
  className = '',
  offsetTop = '0px',
  unisonProjectTask,
  hourHeight,
}: AgendaEventProps) {
  const { task } = unisonProjectTask;
  const offset = getOffset(task.dueAt, hourHeight);

  return (
    <div
      className={twMerge(classes.wrapper, className)}
      style={{ ...styles.wrapper(hourHeight, offset, offsetTop), ...style }}>
      <Event className={classes.event} unisonProjectTask={unisonProjectTask} />
    </div>
  );
}
