import { type CSSProperties, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { renderDateOnlyTime } from '@/utils/time';
import { renderUserName } from '@/utils/strings';
import { useToggle } from '@/hooks/useToggle';
import EventMenu from './EventMenu';
import UnisonProjectTaskDialog from '@/dialogs/UnisonProjectTaskDialog';
import { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import { classes } from './utils';

export type EventProps = {
  style?: CSSProperties;
  className?: string;
  unisonProjectTask: DecoratedUnisonProjectTask;
};

export default function Event({
  style = {},
  className = '',
  unisonProjectTask,
}: EventProps) {
  const eventRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isEditDialogShown, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);
  const { dueAt, isResolved, assignedTo, description } = unisonProjectTask.task;

  const handleEditTask = () => {
    toggleMenu(false);
    toggleEditDialog(true);
  };

  const handleDeleteTask = () => {
    toggleMenu(false);
    toggleDeleteDialog(true);
  };

  return (
    <>
      <div
        ref={eventRef}
        className={className}
        style={style}
        onClick={() => toggleMenu()}>
        <div
          className={twMerge(
            classes.event,
            isResolved ? classes.closed : classes.open
          )}>
          <div className={classes.title}>
            <div className={classes.time}>{renderDateOnlyTime(dueAt)}</div>
            <div className={classes.name}>{renderUserName(assignedTo)}</div>
          </div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
      {isMenuOpen && (
        <EventMenu
          open
          unisonProjectTask={unisonProjectTask}
          anchorTo={eventRef.current}
          onEdit={handleEditTask}
          onClose={() => toggleMenu(false)}
        />
      )}
      {isEditDialogShown && (
        <UnisonProjectTaskDialog
          projectID={unisonProjectTask.unisonProjectID}
          taskID={unisonProjectTask.taskID}
          onClose={() => toggleEditDialog()}
        />
      )}
    </>
  );
}
