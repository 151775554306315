import { useState } from 'react';
import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import Card from '@/components/Card';
import UnisonProjectTasksList from '@/components/UnisonProjectTasksList';
import UnisonProjectTaskDialog from '@/dialogs/UnisonProjectTaskDialog';
import TasksFilterMenu from '@/components/TasksFilterMenu';
import EmptyMessage from '../../EmptyMessage';
import { useAppState } from '@/providers/AppStateProvider';
import {
  usePracticeUsers,
  useTasksFromUnisonProjectID,
} from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { filterTasks, getTaskFilterCount } from '@/utils/filters';
import { classes } from './utils';

export type TasksSectionProps = {
  isFetchingData: boolean;
};

export default function TasksSection({ isFetchingData }: TasksSectionProps) {
  const { activeProjectID } = useAppState();
  const practiceUsers = usePracticeUsers();
  const tasks = useTasksFromUnisonProjectID(activeProjectID);
  const [statuses, setStatuses] = useState([false]);
  const [isTaskDialogShown, toggleTaskDialog] = useToggle(false);
  const [users, setUsers] = useState(practiceUsers.map((u) => u.userID));

  if (isFetchingData || !activeProjectID) return null;

  const handleApplyFilters = ({
    statusFilters,
    userFilters,
  }: {
    statusFilters: boolean[];
    userFilters: number[];
  }) => {
    setStatuses(statusFilters);
    setUsers(userFilters);
  };

  const getFiltersCount = () => {
    return getTaskFilterCount(statuses, users, practiceUsers);
  };

  const renderTaskDialog = () => {
    if (!isTaskDialogShown) return null;
    return (
      <UnisonProjectTaskDialog
        projectID={activeProjectID!}
        onClose={() => toggleTaskDialog(false)}
      />
    );
  };

  const renderTasks = () => {
    if (tasks.length === 0) {
      return <EmptyMessage>No tasks for current project.</EmptyMessage>;
    }
    const filteredTasks = filterTasks(tasks, statuses, users);
    if (filteredTasks.length === 0) {
      return <EmptyMessage>No tasks matching current filters.</EmptyMessage>;
    }
    return (
      <Card className={classes.card}>
        <UnisonProjectTasksList
          className={classes.list}
          isFetchingData={isFetchingData}
          unisonProjectTasks={filteredTasks}
        />
      </Card>
    );
  };

  return (
    <Section>
      {renderTaskDialog()}
      <SectionTitle
        title="Tasks"
        newLabel="New task"
        onAddNew={() => toggleTaskDialog(true)}
        filtersCount={getFiltersCount()}
        renderFilterMenu={(props) => (
          <TasksFilterMenu
            statusFilters={statuses}
            userFilters={users}
            onApplyFilters={handleApplyFilters}
            {...props}
          />
        )}
      />
      {renderTasks()}
    </Section>
  );
}
