import { useChecklist, useDispatch } from '@/store/useStore';
import { addChecklistItem as dispatchAddChecklistItem } from '@/store/slices/checklistItemsSlice';
import { updateChecklist as dispatchUpdateChecklist } from '@/store/slices/checklistsSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import AddIcon from '@/icons/AddIcon';
import ItemInput from '../ItemInput';
import { showWarningToaster } from '@/utils/toasters';
import { addChecklistItem, classes } from './utils';

export type NewChecklistItemProps = {
  checklistID: number;
};

export default function NewChecklistItem({
  checklistID,
}: NewChecklistItemProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const checklist = useChecklist(checklistID);
  const [isEditing, toggleEditing] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  const handleSave = async (description: string) => {
    try {
      if (!checklist) return showWarningToaster('Checklist not found'); // Shouldn't ever happen
      toggleSaving(true);
      const result = await addChecklistItem(checklist, description);
      dispatch(dispatchUpdateChecklist(result.checklist));
      dispatch(dispatchAddChecklistItem(result.item));
      toggleSaving(false);
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error adding item');
    }
  };

  return isEditing ? (
    <ItemInput
      isSaving={isSaving}
      onSave={handleSave}
      onCancel={() => toggleEditing(false)}
    />
  ) : (
    <div className={classes.buttonWrapper}>
      <button className={classes.button} onClick={() => toggleEditing()}>
        <AddIcon className={classes.icon} />
        <span>Add item</span>
      </button>
    </div>
  );
}
