import type { DecoratedChecklist } from '@witmetrics/api-client';
import { useState } from 'react';
import Menu from '@/components/Menu';
import TextField from '@/components/TextField';
import Button from '@/components/Button';
import SearchableDropdown, {
  type SearchableDropdownOption,
} from '@/components/SearchableDropdown';
import { classes, formatTemplateOptions, searchTemplates } from './utils';

export type NewChecklistMenuProps = {
  open: boolean;
  anchorTo: HTMLElement | null;
  checklistTemplates: DecoratedChecklist[];
  onSave: (name: string, templateID?: number) => void;
  onClose: () => void;
};

export default function NewChecklistMenu({
  open = true,
  anchorTo,
  checklistTemplates,
  onSave,
  onClose,
}: NewChecklistMenuProps) {
  const [name, setName] = useState('Checklist');
  const [selectedTemplateID, setSelectedTemplateID] = useState<number | null>(
    null
  );

  const handleSave = () => {
    if (selectedTemplateID) onSave(name, selectedTemplateID);
    else onSave(name);
    onClose();
  };

  const handleSearchTemplates = async (searchQuery: string) => {
    const templates = searchTemplates(searchQuery, checklistTemplates);
    return formatTemplateOptions(templates);
  };

  const handleSelectTemplate = ({
    template,
  }: Record<'template', SearchableDropdownOption<number>>) => {
    setName(template.label);
    setSelectedTemplateID(template.value);
  };

  return (
    <Menu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>New Checklist</span>
        </div>
        <TextField
          name="name"
          label="Name"
          value={name}
          onChange={(update) => setName(update.name)}
        />
        <SearchableDropdown<'template', number>
          blurOnSelect
          clearOnBlur
          className={classes.templateDropdown}
          name="template"
          label="Copy from template"
          initialOptions={formatTemplateOptions(checklistTemplates)}
          onSearch={handleSearchTemplates}
          onSelect={handleSelectTemplate}
        />
        <Button onClick={handleSave}>Save</Button>
      </div>
    </Menu>
  );
}
