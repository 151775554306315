export const classes = {
  wrapper:
    'w-56 rounded-lg bg-grey-2 flex items-center justify-between cursor-pointer overflow-hidden shrink-0 m-1',
  downloadIcon:
    'download-icon cursor-pointer text-2xl text-grey-6 hover:text-grey-7 transition-all',
  downloadWrapper: 'shrink-0 px-2',
  nameWrapper: 'grow',
  name: 'text-grey-8 font-medium text-sm select-none',
  iconWrapper:
    'rounded-lg bg-grey-1 border border-grey-3 flex items-center justify-center p-2 ml-2 mr-4 my-2',
  fileIcon: 'text-lg text-grey-6 select-none',
};

export const styles = {
  wrapper: {
    '& .download-icon': {
      opacity: 0,
    },
    '&:hover .download-icon': {
      opacity: 1,
    },
  },
};
