import { type DecoratedNote } from '@witmetrics/api-client';
import BlockHeader from '../BlockHeader';
import { useCurrentUser } from '@/store/useStore';
import { canEdit } from './utils';

export type NoteBlockHeaderProps = {
  note: DecoratedNote;
  dateFormat?: string;
  onEdit: () => void;
  onDelete: () => void;
};

export default function NoteBlockHeader({
  note,
  dateFormat,
  onEdit,
  onDelete,
}: NoteBlockHeaderProps) {
  const currentUser = useCurrentUser();
  return (
    <BlockHeader
      editable={canEdit(note, currentUser?.id)}
      dateFormat={dateFormat}
      createdBy={note.createdBy}
      createdAt={note.createdAt}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
}
