export const classes = {
  paper: 'flex top-8 rounded-2xl',
  wrapper: 'flex flex-col w-[90vw] max-w-[600px] min-w-[200px] min-h-0',
  inputBase: 'w-full text-2xl px-6 py-4 flex-0',
  resultsList: 'flex-0 max-h-[40vh] overflow-auto',
};

export const styles = {
  dialog: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
  },
};
