import { theme } from '@/utils/theme';

export const classes = {
  listItemButton:
    'transition-colors duration-100 py-2 px-4 my-1 mx-4 rounded-lg',
};

export const styles = {
  listItemButton: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&:hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '& .MuiTypography-body1': {
      fontSize: '1rem', // text-base
      lineHeight: '1.5rem', // text-base
      color: theme.palette['grey-8'].main,
    },
    '& .MuiTypography-body2': {
      fontSize: '0.875rem', // text-sm
      lineHeight: '1.25rem', // text-sm
      color: theme.palette['grey-7'].main,
    },
  },
};
