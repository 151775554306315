import { API } from '@/api';
import type { DecoratedChecklist } from '@witmetrics/api-client';

export const classes = {
  button:
    'py-3 w-[12rem] flex items-center justify-center cursor-pointer bg-grey-2 hover:bg-grey-3 rounded-lg transition-all text-grey-7',
  buttonWrapper: 'w-full flex justify-center mt-4',
  icon: 'text-grey-6 mr-2',
};

export async function addChecklistItem(
  checklist: DecoratedChecklist,
  description: string
) {
  const result = await API.Checklists.addItemToChecklist(checklist.id, {
    description,
  });
  const { itemOrder, updatedAt, updatedBy } = result.checklist;
  return {
    item: result.item,
    checklist: { ...checklist, itemOrder, updatedAt, updatedBy },
  };
}
