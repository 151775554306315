import { type DecoratedNote } from '@witmetrics/api-client';
import NewComment from './NewComment';
import Comment from './Comment';
import List from '@mui/material/List';
import { useCurrentUser } from '@/store/useStore';
import { sortByCreatedAt } from '@/utils/arrays';
import { classes, styles } from './utils';

export type CommentsSectionProps = {
  note: DecoratedNote;
};

export default function CommentsSection({ note }: CommentsSectionProps) {
  const currentUser = useCurrentUser();
  if (!currentUser) return null;
  return (
    <div className={classes.wrapper}>
      {note.noteComments.length > 0 && (
        <List sx={styles.list}>
          {sortByCreatedAt(note.noteComments, -1).map((comment) => (
            <Comment
              key={comment.id}
              editable={comment.createdBy?.id === currentUser.id}
              comment={comment}
            />
          ))}
        </List>
      )}
      <div className={classes.newCommentWrapper}>
        <NewComment noteID={note.id} />
      </div>
    </div>
  );
}
