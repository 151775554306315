export const classes = {
  event: 'w-full h-full border rounded-lg p-2 transition-all cursor-pointer',
  open: 'text-white bg-purple-2 border-purple-2 hover:border-purple-3',
  closed:
    'text-grey-5 bg-grey-2 border-grey-4 line-through hover:no-underline hover:border-grey-5',
  time: 'text-sm font-normal',
  title: 'flex justify-between items-start flex-wrap',
  name: 'font-medium text-sm truncate',
  description: 'font-normal text-sm truncate',
};
