import { useState } from 'react';
import List from '@mui/material/List';
import { type DecoratedConversationUser } from '@witmetrics/api-client';
import { API } from '@/api';
import MembersListItem from './MembersListItem';
import Accordion from '@/components/Accordion';
import ConversationMembersDialog from '@/dialogs/ConversationMembersDialog';
import SelectConversationAdminsDialog from '@/dialogs/SelectConversationAdminsDialog';
import LastMemberWarningDialog from '@/dialogs/LastMemberWarningDialog';
import Button from '@mui/material/Button';
import { useToggle } from '@/hooks/useToggle';
import { useConversation, useCurrentUser, useDispatch } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import {
  deleteConversationUser as dispatchDeleteConversationUser,
  setConversationUsers as dispatchSetConversationUsers,
  updateConversationUser as dispatchUpdateConversationUser,
} from '@/store/slices/conversationUsersSlice';
import { deleteConversation as dispatchDeleteConversation } from '@/store/slices/conversationsSlice';
import { showSuccessToaster } from '@/utils/toasters';
import {
  ADMIN_SELECT_MODE,
  checkIsAdmin,
  checkLeavingMember,
  classes,
  leaveConversation,
  saveNewAdmins,
  updateAdmins,
} from './utils';

const { REPLACING, LEAVING } = ADMIN_SELECT_MODE;

export type MembersListProps = {
  conversationID: number;
  members: DecoratedConversationUser[];
  onLeaveConversation: (conversationID: number) => void;
};

export default function MembersList({
  conversationID,
  members,
  onLeaveConversation,
}: MembersListProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const conversation = useConversation(conversationID);
  const [adminSelectMode, setAdminSelectMode] = useState<
    keyof typeof ADMIN_SELECT_MODE | null
  >(null);
  const [isLastMemberWarningOpen, toggleLastMemberWarning] = useToggle(false);
  const [isAddDialogOpen, toggleAddDialog] = useToggle(false);
  const isAdmin = checkIsAdmin(currentUser!, members);

  const handleUpdateMember = async (
    userID: number,
    userConversationStatusID: number
  ) => {
    try {
      const { isLastAdmin } = checkLeavingMember(userID, members);
      if (isLastAdmin) {
        // Select new admin but don't leave the convo
        setAdminSelectMode(REPLACING);
      } else {
        const updatedUser = await API.Conversations.updateConversationUser(
          conversationID,
          userID,
          { userConversationStatusID }
        );
        dispatch(dispatchUpdateConversationUser(updatedUser));
      }
    } catch (err) {
      onApiError(err, 'Error updating member status');
    }
  };

  const handleRemoveMember = async (userID: number) => {
    try {
      const { isLastMember, isLastAdmin } = checkLeavingMember(userID, members);
      if (isLastMember) {
        // Confirm they're ok with the convo being deleted if they leave
        return toggleLastMemberWarning(true);
      } else if (isLastAdmin) {
        // Need to select a new admin before leaving
        setAdminSelectMode(LEAVING);
      } else {
        await API.Conversations.removeConversationUser(conversationID, userID);
        dispatch(dispatchDeleteConversationUser({ conversationID, userID }));
      }
    } catch (err) {
      onApiError(err, 'Error removing member');
    }
  };

  const handleLeaveAsAdmin = async (
    conversationID: number,
    adminIDs: number[]
  ) => {
    // NOTE: try/catch being handled by SelectConversationAdminsDialog
    // Add the new admins before leaving
    await saveNewAdmins(conversationID, adminIDs);
    // Leave the conversation
    await leaveConversation(conversationID, currentUser!.id);
    dispatch(dispatchDeleteConversation({ conversationID }));
    showSuccessToaster("You've left the conversation");
    return onLeaveConversation(conversationID);
  };

  const handleUpdateAdmins = async (
    conversationID: number,
    adminIDs: number[]
  ) => {
    // NOTE: try/catch being handled by SelectConversationAdminsDialog
    const updatedMembers = await updateAdmins(
      conversationID,
      currentUser!.id,
      adminIDs
    );
    dispatch(dispatchSetConversationUsers(updatedMembers));
    return null;
  };

  const handleRemoveLastMember = () => {
    toggleLastMemberWarning(false);
    onLeaveConversation(conversationID);
  };

  const renderListItem = (member: DecoratedConversationUser) => {
    const isCreator = conversation!.createdBy?.id === member.user.id;
    const isLastMember = members.length === 1;
    return (
      <MembersListItem
        key={member.user.id}
        showOptions={isAdmin}
        showAdminOption={!(isCreator || isLastMember)}
        member={member}
        onUpdate={handleUpdateMember}
        onRemove={handleRemoveMember}
      />
    );
  };

  return (
    <>
      <Accordion
        label="Members"
        className={classes.accordion}
        headerClassName={classes.header}
        labelClassName={classes.title}>
        <div className={classes.wrapper}>
          <List>{members.map(renderListItem)}</List>
          <div className={classes.addWrapper}>
            <Button
              // @ts-ignore -- MUI not recognizing custom palette
              color="purple-2"
              className={classes.addButton}
              onClick={() => toggleAddDialog()}>
              Add members
            </Button>
          </div>
        </div>
      </Accordion>
      {isAddDialogOpen && (
        <ConversationMembersDialog
          conversationID={conversationID}
          onClose={() => toggleAddDialog(false)}
        />
      )}
      {isLastMemberWarningOpen && (
        <LastMemberWarningDialog
          conversationID={conversationID}
          onLeaveConversation={handleRemoveLastMember}
          onClose={() => toggleLastMemberWarning(false)}
        />
      )}
      {adminSelectMode !== null && (
        <SelectConversationAdminsDialog
          conversationID={conversationID}
          confirmLabel={adminSelectMode === LEAVING ? 'Save & Leave' : 'Save'}
          onSave={
            adminSelectMode === LEAVING
              ? handleLeaveAsAdmin
              : handleUpdateAdmins
          }
          onClose={() => setAdminSelectMode(null)}
        />
      )}
    </>
  );
}
