import { type UndecoratedNote } from '@witmetrics/api-client';
import { sortByCreatedAt } from '@/utils/arrays';
import NoteBlock from './NoteBlock';
import { classes } from './utils';

export type NotesTimelineProps = {
  notes: UndecoratedNote[];
};

export default function NotesTimeline({ notes }: NotesTimelineProps) {
  return (
    <div className={classes.wrapper}>
      {sortByCreatedAt(notes).map((note) => (
        <NoteBlock key={note.id} noteID={note.id} />
      ))}
    </div>
  );
}
