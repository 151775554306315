import { useState } from 'react';
import { type UndecoratedUser } from '@witmetrics/api-client';
import SearchField from '@/components/SearchField';
import { isEmpty } from '@/utils/arrays';
import List from '@mui/material/List';
import ListItem from './ListItem';
import DeleteIcon from '@/icons/DeleteIcon';
import AddIcon from '@/icons/AddIcon';
import Divider from '@/components/Divider';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { useCurrentUser } from '@/store/useStore';
import { classes, getAvailableMembers, searchUsers } from './utils';

export type MemberSelectorProps = {
  isFetchingData: boolean;
  options: UndecoratedUser[];
  selected: UndecoratedUser[];
  onChange: (selected: UndecoratedUser[]) => void;
};

export default function MemberSelector({
  isFetchingData,
  options,
  selected,
  onChange,
}: MemberSelectorProps) {
  const currentUser = useCurrentUser();
  const [search, setSearch] = useState('');

  const handleRemove = (userID: number) => {
    return onChange(selected.filter((u) => u.id !== userID));
  };

  const handleAdd = (user: UndecoratedUser) => {
    return onChange([...selected, user]);
  };

  const renderFetching = () => {
    return (
      <div className={classes.message}>
        Fetching members
        <LoopingEllipsis />
      </div>
    );
  };

  const renderSelected = () => {
    if (isEmpty(selected)) {
      return <div className={classes.message}>No members selected.</div>;
    }
    return (
      <List className={classes.list}>
        {selected.map((user) => (
          <ListItem
            key={user.id}
            user={user}
            icon={<DeleteIcon />}
            onClick={() => handleRemove(user.id)}
          />
        ))}
      </List>
    );
  };

  const renderOptions = () => {
    if (isFetchingData) return renderFetching();
    if (isEmpty(options)) {
      return <div className={classes.message}>No available users.</div>;
    }
    let users = getAvailableMembers(options, selected, currentUser!);
    if (isEmpty(users)) {
      return <div className={classes.message}>All members selected.</div>;
    }
    users = searchUsers(users, search);
    if (isEmpty(users)) {
      return (
        <div className={classes.message}>
          No members matching current search.
        </div>
      );
    }
    return (
      <List className={classes.list}>
        {users.map((user) => (
          <ListItem
            key={user.id}
            user={user}
            icon={<AddIcon />}
            onClick={() => handleAdd(user)}
          />
        ))}
      </List>
    );
  };

  return (
    <div>
      <div className={classes.title}>Selected</div>
      {renderSelected()}
      <Divider className={classes.divider} />
      <div className={classes.title}>Options</div>
      <SearchField
        className={classes.search}
        value={search}
        onChange={(update) => setSearch(update)}
      />
      {renderOptions()}
    </div>
  );
}
