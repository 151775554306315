import { CSSProperties } from 'react';
import {
  areIntervalsOverlapping,
  isSameDay,
  isWeekend,
  parseISO,
} from 'date-fns';
import { getTimeOfDayMs } from '@/utils/time';
import { DecoratedUnisonProjectTask } from '@witmetrics/api-client';

export const classes = {
  wrapper: 'relative py-12 h-full min-h-0',
};

export function getHours() {
  let result = [];
  for (let i = 0; i < 24; i++) {
    result.push(i);
  }
  return result;
}

export function isOffHour(hour: number, date: string) {
  return isWeekend(parseISO(date)) || hour < 8 || hour > 18;
}

export function getCurrentTasks(
  tasks: DecoratedUnisonProjectTask[],
  date: string
) {
  const parsedDate = parseISO(date);
  return tasks
    .filter((upt) => isSameDay(parseISO(upt.task.dueAt), parsedDate))
    .sort((a, b) => a.task.dueAt.localeCompare(b.task.dueAt));
}

export function sortEvents(
  tasks: DecoratedUnisonProjectTask[],
  leftOffset = 0
) {
  let result: {
    unisonProjectTask: DecoratedUnisonProjectTask;
    style: CSSProperties;
  }[] = [];
  tasks.forEach((upt) => {
    const start = new Date(upt.task.dueAt);
    let end = new Date(upt.task.dueAt);
    end.setHours(end.getHours() + 1);
    const overlaps = getOverlaps(tasks, start.toISOString(), end.toISOString());
    result.push({
      unisonProjectTask: upt,
      style: {
        width: `calc((100% - ${leftOffset}px) / ${overlaps.length})`,
        left: `calc(${leftOffset}px + ${overlaps.indexOf(
          upt.task.id
        )} * ((100% - ${leftOffset}px) / ${overlaps.length}))`,
      },
    });
  });
  return result;
}

function getOverlaps(
  tasks: DecoratedUnisonProjectTask[],
  startsAt: string,
  endsAt: string
) {
  const start = parseISO(startsAt);
  const end = parseISO(endsAt);
  return tasks
    .filter((upt) => {
      let taskEnd = new Date(upt.task.dueAt);
      taskEnd.setHours(taskEnd.getHours() + 1);
      return areIntervalsOverlapping(
        { start, end },
        { start: parseISO(upt.task.dueAt), end: taskEnd }
      );
    })
    .map((e) => e.task.id);
}

export function getOffsetHeight(hourHeight: number) {
  // Scrolling to 1 hour before the current time
  const totalLength = 24 * 60 * 60 * 1000; // 24 hours to ms
  return 24 * hourHeight * (getTimeOfDayMs() / totalLength) - hourHeight;
}
