import InfoHeader from './InfoHeader';
import MembersList from './MembersList';
import { useConversationUsers } from '@/store/useStore';
import { buildMembersList, classes } from './utils';

export type ConversationInfoProps = {
  conversationID: number | null;
  onLeaveConversation: (conversationID: number) => void;
  onClose: () => void;
};

export default function ConversationInfo({
  conversationID,
  onLeaveConversation,
  onClose,
}: ConversationInfoProps) {
  const conversationUsers = useConversationUsers(conversationID);
  const members = buildMembersList(conversationUsers);
  return (
    <div className={classes.wrapper}>
      <InfoHeader onClose={onClose} />
      {conversationID && (
        <MembersList
          conversationID={conversationID}
          members={members}
          onLeaveConversation={onLeaveConversation}
        />
      )}
    </div>
  );
}
