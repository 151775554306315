import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { classes, styles } from './utils';
import { UndecoratedPractice } from '@witmetrics/api-client';

export interface PracticesListProps extends Omit<ListProps, 'onSelect'> {
  practices?: UndecoratedPractice[];
  onSelect: (practiceID: number) => void;
}

export default function PracticesList({
  practices = [],
  onSelect,
  ...props
}: PracticesListProps) {
  return (
    <List {...props}>
      {practices.map((practice) => (
        <ListItem key={practice.id} disablePadding>
          <ListItemButton
            sx={styles.listItemButton}
            className={classes.listItemButton}
            onClick={() => onSelect(practice.id)}>
            <ListItemText primary={practice.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
