import { type ReactNode } from 'react';
import CloseIcon from '@/icons/CloseIcon';
import { classes } from './utils';

export type AttachmentProps = {
  name: string;
  renderIcon: (className: string) => ReactNode;
  onRemove: () => void;
};

export default function Attachment({
  name,
  renderIcon,
  onRemove,
}: AttachmentProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.detailsWrapper}>
        <div className={classes.iconWrapper}>{renderIcon(classes.icon)}</div>
        <div className={classes.name}>{name}</div>
      </div>
      <CloseIcon className={classes.removeIcon} onClick={onRemove} />
    </div>
  );
}
