import { API } from '@/api';
import { SORT_ORDER } from '@/constants/sortOrder';
import { fetchFileCollectionData } from '@/utils/files';
import {
  type DecoratedFileCollection,
  type DecoratedFileCollectionFile,
} from '@witmetrics/api-client';

export const classes = {
  wrapper: 'h-full w-full flex',
  fetching:
    'flex items-center justify-center h-full w-full text-xl text-grey-6',
  leftColumn: 'grow min-w-[500px] flex flex-col',
  rightColumn: 'w-1/3 min-w-[275px] max-w-[400px] border-l border-grey-3',
  messages: 'grow overflow-y-auto min-h-0',
};

export function conversationSocket(conversationID: number) {
  return `conversation:${conversationID}`;
}

export async function fetchConversationData(conversationID: number) {
  const [conversation, conversationMessages, conversationUsers, unisonProject] =
    await Promise.all([
      API.Conversations.fetchConversation(conversationID),
      API.Conversations.fetchConversationMessages(conversationID, {
        filters: [],
        pagination: { page: 1, pageSize: 50 },
        sorting: { sortBy: 'sentAt', sortOrder: SORT_ORDER.DESCENDING },
      }),
      API.Conversations.fetchConversationUsers(conversationID, {
        filters: [],
        pagination: { page: 1, pageSize: 100 }, // Should ensure everyone gets returned
      }),
      API.Conversations.fetchConversationUnisonProject(conversationID),
    ]);
  // Fetch fileCollection contents for any top-level message collections
  const fileCollectionIDs = conversationMessages.results
    .flatMap((m) => m.fileCollections)
    .map((fc) => fc.fileCollectionID);
  let fileCollections: DecoratedFileCollection[] = [];
  let files: DecoratedFileCollectionFile[] = [];
  for (const fileCollectionID of fileCollectionIDs) {
    const data = await fetchFileCollectionData(fileCollectionID);
    fileCollections.push(...data.fileCollections);
    files.push(...data.files);
  }
  return {
    conversation,
    conversationMessages,
    conversationUsers,
    fileCollections,
    files,
    unisonProject,
  };
}
