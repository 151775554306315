import { API } from '@/api';

export const classes = {
  menu: 'px-4 pb-2 pt-4 m-2 max-w-[98vw] w-[375px]',
  title: 'flex justify-start items-center mb-4',
  wrapper: 'px-2',
  details: 'mt-2 mb-4',
  project:
    'text-grey-8 text-lg font-medium cursor-pointer hover:underline truncate',
  description: 'text-grey-8 leading-5 text-base font-normal',
  dueAt: 'text-grey-7 text-base font-normal ml-2',
};

export async function updateIsResolved(taskID: number, isResolved: boolean) {
  return API.Tasks.updateTask(taskID, { isResolved });
}
