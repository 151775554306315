import { useEffect, useState } from 'react';
import { useChecklist, useDispatch } from '@/store/useStore';
import { updateChecklist as dispatchUpdateChecklist } from '@/store/slices/checklistsSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import { showSuccessToaster, showWarningToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, updateChecklist, validateFields } from './utils';

export type ChecklistDialogProps = {
  checklistID: number;
  onClose: () => void;
};

export default function ChecklistDialog({
  checklistID,
  onClose,
}: ChecklistDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const checklist = useChecklist(checklistID);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (checklist) fillFields();
  }, [checklist]);

  const fillFields = () => {
    setName(checklist!.name);
    setDescription(checklist!.description || '');
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalidFields = validateFields({ name });
      if (invalidFields.length > 0) {
        showWarningToaster('Please enter a checklist name');
        toggleSaving(false);
        return setInvalidFields(invalidFields);
      }
      const data = { name, description };
      const updatedChecklist = await updateChecklist(checklistID, data);
      dispatch(dispatchUpdateChecklist(updatedChecklist));
      showSuccessToaster('Checklist updated');
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving checklist', () => handleSave());
    }
  };

  const isSaveDisabled = () => {
    return isSaving || validateFields({ name }).length > 0;
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Edit Checklist" onClose={onClose} />
      <DialogBody className={classes.body}>
        <TextField
          error={invalidFields.includes('name')}
          className={classes.name}
          name="name"
          label="Name"
          value={name}
          onChange={(update) => setName(update.name)}
        />
        <TextField
          multiline
          className={classes.description}
          minRows={3}
          maxRows={5}
          name="description"
          label="Description"
          value={description}
          onChange={(update) => setDescription(update.description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
