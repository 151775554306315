import { isBlank, renderUserName } from '@/utils/strings';
import { mapToID } from '@/utils/arrays';
import { UndecoratedUser } from '@witmetrics/api-client';

export const classes = {
  message: 'text-base text-grey-7 text-center py-4',
  list: 'max-h-[250px] overflow-y-auto',
  title: 'text-base font-medium text-grey-8',
  divider: 'my-4',
  search: 'w-full my-4',
};

export function searchUsers(users: UndecoratedUser[], search: string) {
  if (isBlank(search)) return users;
  const query = search.toLowerCase().trim();
  return users.filter((u) => renderUserName(u).toLowerCase().includes(query));
}

export function getAvailableMembers(
  users: UndecoratedUser[],
  newAdmins: UndecoratedUser[],
  currentUser: UndecoratedUser
) {
  const options = [...mapToID(newAdmins), currentUser.id];
  return users.filter((u) => !options.includes(u.id));
}
