import TruncatedText from '@/components/TruncatedText';
import InfoIcon from '@/icons/InfoIcon';
import ArrowLeftIcon from '@/icons/ArrowLeftIcon';
import { useConversation } from '@/store/useStore';
import { classes } from './utils';

export type ConversationHeaderProps = {
  infoOpen: boolean;
  conversationID: number | null;
  onOpenInfo: () => void;
  onClose: () => void;
};

export default function ConversationHeader({
  infoOpen,
  conversationID,
  onOpenInfo,
  onClose,
}: ConversationHeaderProps) {
  const conversation = useConversation(conversationID);

  if (!conversation) return null;
  return (
    <div className={classes.header}>
      <div className={classes.leftContent}>
        <ArrowLeftIcon className={classes.closeButton} onClick={onClose} />
        <TruncatedText className={classes.subject}>
          {conversation.subject}
        </TruncatedText>
      </div>
      {!infoOpen && (
        <InfoIcon className={classes.infoButton} onClick={onOpenInfo} />
      )}
    </div>
  );
}
