import { useRef } from 'react';
import { useDispatch } from '@/store/useStore';
import { twMerge } from 'tailwind-merge';
import { updateUnisonProject } from '@/store/slices/unisonProjectsSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import type { DecoratedUnisonProject } from '@witmetrics/api-client';
import CircularProgress from '@mui/material/CircularProgress';
import ProjectStatusCircle from '@/components/ProjectStatusCircle';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import ProjectStatusDropdown from '@/components/ProjectStatus/ProjectStatusDropdown';
import { classes, updateProjectStatus } from './utils';

export type ProjectStatusProps = {
  project: DecoratedUnisonProject;
  className?: string;
};

export default function ProjectStatus({
  project,
  className = '',
}: ProjectStatusProps) {
  const statusRef = useRef(null);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [isDropdownOpen, toggleDropdown] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  const handleStatusChange = async (statusID: number) => {
    try {
      toggleSaving(true);
      toggleDropdown(false);
      const updatedProject = await updateProjectStatus(project.id, statusID);
      dispatch(updateUnisonProject(updatedProject));
      toggleSaving(false);
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error updating status');
    }
  };

  return (
    <>
      <button
        ref={statusRef}
        className={twMerge(classes.button, className)}
        onClick={() => toggleDropdown()}>
        {isSaving ? (
          <>
            <CircularProgress
              size={14}
              // @ts-ignore -- Mui not recognizing custom palette
              color="grey-6"
              className={classes.projectStatusCircle}
            />
            <span>
              Saving
              <LoopingEllipsis />
            </span>
          </>
        ) : (
          <>
            <ProjectStatusCircle
              className={classes.projectStatusCircle}
              size={14}
              projectStatusID={project.unisonProjectStatusID}
            />
            {project.unisonProjectStatus.label}
          </>
        )}
      </button>
      {isDropdownOpen && (
        <ProjectStatusDropdown
          open
          anchorTo={statusRef.current}
          onChange={handleStatusChange}
          onClose={() => toggleDropdown(false)}
        />
      )}
    </>
  );
}
