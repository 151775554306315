import { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import Menu, { type MenuProps } from '@/components/Menu';
import Divider from '@/components/Divider';
import AssignedTo from './AssignedTo';
import MenuFooter from './MenuFooter';
import DeleteTaskDialog from '@/dialogs/DeleteTaskDialog';
import { useAppState } from '@/providers/AppStateProvider';
import { useDateFormatPreference, useDispatch } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { renderDateWithTime } from '@/utils/time';
import CheckBox from '@/components/CheckBox';
import { updateTask as dispatchUpdateTask } from '@/store/slices/tasksSlice';
import { classes, updateIsResolved } from './utils';

export type EventMenuProps = {
  open?: boolean;
  unisonProjectTask: DecoratedUnisonProjectTask;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  onEdit: () => void;
  onClose: () => void;
};

export default function EventMenu({
  open = true,
  unisonProjectTask,
  anchorTo,
  anchorOrigin = {
    vertical: 'center',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'center',
    horizontal: 'left',
  },
  onEdit,
  onClose,
}: EventMenuProps) {
  const dispatch = useDispatch();
  const { onSelectProject, onApiError } = useAppState();
  const dateFormat = useDateFormatPreference();
  const [isDeleteWarningShown, toggleDeleteWarning] = useToggle(false);
  const [isUpdating, toggleUpdating] = useToggle(false);

  const { task } = unisonProjectTask;

  const handleToggleChecked = async (isResolved: boolean) => {
    try {
      toggleUpdating(true);
      const updatedTask = await updateIsResolved(task.id, isResolved);
      dispatch(dispatchUpdateTask(updatedTask));
      toggleUpdating(false);
    } catch (err) {
      toggleUpdating(false);
      onApiError(err, 'Error saving task');
    }
  };

  const handleSelectProject = () => {
    onSelectProject(unisonProjectTask.unisonProjectID);
    onClose();
  };

  const handleEdit = () => {
    onEdit();
    onClose();
  };

  const handleDelete = () => {
    toggleDeleteWarning(true);
  };

  return (
    <>
      <Menu
        open={open}
        className={classes.menu}
        anchorTo={anchorTo}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={onClose}>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            <CheckBox
              name="isResolved"
              isUpdating={isUpdating}
              isChecked={task.isResolved}
              onChange={({ isResolved }) => handleToggleChecked(isResolved)}
            />
            <h3 className={classes.dueAt}>
              {renderDateWithTime(task.dueAt, dateFormat)}
            </h3>
          </div>
          <Divider />
          <div className={classes.details}>
            <h2 className={classes.project} onClick={handleSelectProject}>
              {unisonProjectTask.unisonProject.name}
            </h2>
            <p className={classes.description}>{task.description}</p>
          </div>
        </div>
        {task.assignedTo && (
          <>
            <Divider />
            <AssignedTo assignedTo={task.assignedTo} />
          </>
        )}
        <Divider />
        <MenuFooter onEdit={handleEdit} onDelete={handleDelete} />
      </Menu>
      {isDeleteWarningShown && (
        <DeleteTaskDialog
          taskID={task.id}
          onClose={() => toggleDeleteWarning(false)}
        />
      )}
    </>
  );
}
