import { API } from '@/api';
import { getAuthURL, getPracticeLogoURL } from '@/api/getURL';
import PlatformSelector from './PlatformSelector';
import HeaderLogo from '@/components/HeaderLogo';
import UserMenu from './UserMenu';
import { showErrorToaster } from '@/utils/toasters';
import { usePracticeLogo } from '@/store/useStore';
import IconButton from '@/components/IconButton';
import AddIcon from '@/icons/AddIcon';
import SearchIcon from '@/icons/SearchIcon';
import { useAppState } from '@/providers/AppStateProvider';
import { DIALOGS } from '@/constants/dialogs';
import { classes, HEADER_HEIGHT } from './utils';

export default function AppHeader() {
  const { onOpenDialog } = useAppState();
  const logo = usePracticeLogo();
  const logoSrc = logo?.value ? getPracticeLogoURL(`${logo.value}`) : null;

  const handleOpenShortcuts = () => onOpenDialog(DIALOGS.SHORTCUTS);

  const handleOpenSearch = () => onOpenDialog(DIALOGS.SEARCH);

  const handleLogout = async () => {
    try {
      await API.Users.logout();
      // @ts-ignore
      window.location = `${getAuthURL()}/login`;
    } catch (err) {
      showErrorToaster('Error logging out');
      throw err;
    }
  };

  return (
    <div className={classes.header}>
      <HeaderLogo src={logoSrc} />
      <div className={classes.buttonsWrapper}>
        <IconButton
          aria-label="search"
          className={classes.icon}
          onClick={handleOpenSearch}>
          <SearchIcon />
        </IconButton>
        <IconButton
          aria-label="shortcuts"
          className={classes.icon}
          onClick={handleOpenShortcuts}>
          <AddIcon />
        </IconButton>
        <PlatformSelector />
        <UserMenu onLogout={handleLogout} />
      </div>
    </div>
  );
}

export { HEADER_HEIGHT };
