import type {
  DecoratedNote,
  DecoratedUnisonProject,
  DecoratedUnisonProjectNote,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { buildUserOption } from '@/utils/filters';
import { type SearchableDropdownOption } from '@/components/SearchableDropdown';
import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[98vw] max-w-[1000px] min-w-[500px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  section: 'w-full max-w-[500px]',
  userInput: 'w-full my-2',
  textField: 'w-full my-2',
  notes: 'w-full mt-2 mb-8',
};

export function validateFields({
  name,
  userID,
}: {
  name: string;
  userID?: number;
}) {
  let invalidFields = [];
  if (isBlank(name) && !userID) invalidFields.push('name');
  return invalidFields;
}

export async function createUnisonProject(
  data: {
    name: string;
    description: string;
    userID?: number;
  },
  notes: string
): Promise<{
  project: DecoratedUnisonProject;
  note?: DecoratedNote;
  projectNote?: DecoratedUnisonProjectNote;
}> {
  if (notes.length > 0) {
    const [project, note] = await Promise.all([
      API.UnisonProjects.createNewProject(data),
      API.Notes.createNewNote({ content: notes.trim() }),
    ]);
    const projectNote = await API.UnisonProjects.addNoteToUnisonProject(
      project.id,
      note.id
    );
    return { project, note, projectNote };
  } else {
    const project = await API.UnisonProjects.createNewProject(data);
    return { project };
  }
}

export function updateUnisonProject(
  projectID: number,
  data: { name: string; description: string; userID?: number }
) {
  return API.UnisonProjects.updateUnisonProject(projectID, data);
}

export async function searchLeads(searchQuery: string) {
  const search = searchQuery.toLowerCase().trim();
  let leadSuggestions: SearchableDropdownOption<number>[] = [];
  if (search.length > 0) {
    const leads = await API.Leads.fetchMatchingLeads({
      search,
      fields: ['firstname', 'lastname'],
      properties: [],
      filters: [],
      pagination: { page: 1, pageSize: 25 },
    });
    leadSuggestions = leads.results.map(buildUserOption);
  }
  return leadSuggestions;
}
