'use client';
import { type PropsWithChildren, useEffect, useRef } from 'react';
import AppHeader from '@/components/AppHeader';
import DialogsManager from '@/components/DialogsManager';
import OverlaysManager from '@/components/OverlaysManager';
import { useAppState } from '@/providers/AppStateProvider';
import { showErrorToaster, showWarningToaster } from '@/utils/toasters';
import {
  useActiveAccount,
  useCurrentUser,
  useDispatch,
} from '@/store/useStore';
import { setCurrentUser } from '@/store/slices/currentUserSlice';
import { setPracticePreferences } from '@/store/slices/practicePreferencesSlice';
import { setPracticeUsers } from '@/store/slices/userPracticesSlice';
import { getAuthURL } from '@/api/getURL';
import { API } from '@/api';
import { UnauthorizedError } from '@witmetrics/api-client';
import CheckingAuth from '@/components/CheckingAuth';
import ProjectOverlay from '@/overlays/ProjectOverlay';
import { isInput } from '@/utils/dom';
import { DIALOGS } from '@/constants/dialogs';
import { OVERLAYS } from '@/constants/overlays';
import { SHORTCUT_KEYS } from '@/constants/shortcutKeys';
import { NAVIGATION_KEYS } from '@/constants/navigationKeys';
import { usePath } from '@/hooks/usePath';
import type { Dictionary } from '@/types';
import AppSidebar from '@/components/AppSidebar';
import {
  classes,
  fetchPracticeData,
  getNavigation,
  getShortcut,
  isIgnoredKey,
  styles,
} from './utils';

export default function Layout({ children }: PropsWithChildren) {
  const shiftCount = useRef(0);
  const dispatch = useDispatch();
  const { searchParams, navigate } = usePath();
  const activeAccount = useActiveAccount();
  const currentUser = useCurrentUser();
  const {
    onApiError,
    activeProjectID,
    activeDialogs,
    setActiveDialogs,
    onSelectProject,
    onOpenAgenda,
  } = useAppState();

  useEffect(() => {
    handleFetchCurrentUser();
    checkActiveProjectParams();
  }, []);

  useEffect(() => {
    function checkKeyPress(e: KeyboardEvent) {
      return handleKeyPress(e, activeDialogs);
    }
    window.addEventListener('keyup', checkKeyPress);
    return () => {
      window.removeEventListener('keyup', checkKeyPress);
    };
  }, [activeDialogs]);

  useEffect(() => {
    if (activeAccount) handleFetchPracticeData();
  }, [activeAccount]);

  const checkActiveProjectParams = () => {
    const projectID = searchParams.get('activeProjectID');
    if (projectID && parseInt(projectID) !== activeProjectID) {
      onSelectProject(parseInt(projectID));
    }
  };

  const handleFetchCurrentUser = async () => {
    try {
      const user = await API.Users.fetchCurrentUser();
      dispatch(setCurrentUser(user));
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        navigate(getAuthURL(), { redirect: window.location.href });
      } else {
        showErrorToaster('Error fetching current user');
        throw err;
      }
    }
  };

  const handleFetchPracticeData = async () => {
    try {
      const data = await fetchPracticeData(activeAccount!.accountID);
      dispatch(setPracticePreferences(data.practicePreferences));
      dispatch(setPracticeUsers(data.practiceUsers));
    } catch (err) {
      onApiError(err, 'Error fetching practice settings', () =>
        handleFetchPracticeData()
      );
    }
  };

  const handleSelectShortcut = (shortcut: keyof typeof SHORTCUT_KEYS) => {
    let updates: { [d: string]: boolean } = { [DIALOGS.SHORTCUTS]: false };
    const key = SHORTCUT_KEYS[shortcut];
    if (key === OVERLAYS.AGENDA) onOpenAgenda(new Date().toISOString());
    else updates[key] = true;
    setActiveDialogs((dialogs) => ({ ...dialogs, ...updates }));
  };

  const handleSelectNavigation = (shortcut: keyof typeof NAVIGATION_KEYS) => {
    setActiveDialogs((d) => ({
      ...d,
      [DIALOGS.NAVIGATION]: false,
    }));
    navigate(NAVIGATION_KEYS[shortcut]);
  };

  const handleKeyPress = (e: KeyboardEvent, dialogs: Dictionary<boolean>) => {
    if (isInput(e)) return null;
    const isSearchDialogShown = dialogs[DIALOGS.SEARCH];
    const isShortcutsDialogShown = dialogs[DIALOGS.SHORTCUTS];
    const isNavigationDialogShown = dialogs[DIALOGS.NAVIGATION];
    if (e.key === 'Escape' && isSearchDialogShown) {
      shiftCount.current = 0;
      return setActiveDialogs((d) => ({
        ...d,
        [DIALOGS.SEARCH]: false,
        [DIALOGS.SHORTCUTS]: false,
        [DIALOGS.NAVIGATION]: false,
      }));
    }
    if (['\\', ':'].includes(e.key) && !isNavigationDialogShown) {
      return setActiveDialogs((d) => ({ ...d, [DIALOGS.SHORTCUTS]: true }));
    }
    if (e.key === '/' && !isShortcutsDialogShown) {
      return setActiveDialogs((d) => ({ ...d, [DIALOGS.NAVIGATION]: true }));
    }
    if (isShortcutsDialogShown) {
      const shortcut = getShortcut(e);
      if (shortcut) return handleSelectShortcut(shortcut);
    } else if (isNavigationDialogShown) {
      const shortcut = getNavigation(e);
      if (shortcut) return handleSelectNavigation(shortcut);
    }
    if (isShortcutsDialogShown || isNavigationDialogShown) {
      if (isIgnoredKey(e)) return; // Ignore shift key since it's probably just being used as a modifier key
      return showWarningToaster(`Unrecognized shortcut: ${e.key}`);
    }
    if (e.key !== 'Shift' || isSearchDialogShown) return null;
    if (shiftCount.current === 1) {
      shiftCount.current = 0;
      return setActiveDialogs((d) => ({ ...d, [DIALOGS.SEARCH]: true }));
    } else if (shiftCount.current === 0) {
      shiftCount.current = 1;
      // Stop listening if there's more than half a second between key presses
      setTimeout(() => (shiftCount.current = 0), 500);
    } else {
      shiftCount.current = 0;
    }
  };

  if (!currentUser) return <CheckingAuth />;

  return (
    <div className={classes.wrapper}>
      {activeProjectID && <ProjectOverlay />}
      <DialogsManager
        onSelectShortcut={handleSelectShortcut}
        onSelectNavigation={handleSelectNavigation}
      />
      <OverlaysManager />
      <AppHeader />
      <div className={classes.body} style={styles.body}>
        <AppSidebar />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
}
