'use client';
import { useEffect, useRef, useState } from 'react';
import { usePath } from '@/hooks/usePath';
import { Tab } from '@/components/TabsBlock';
import Card from '@/components/Card';
import TasksSection from './TasksSection';
import Conversation from '@/components/Conversation';
import ConversationsSection from './ConversationsSection';
import Divider from '@/components/Divider';
import { classes } from './utils';

export type OverviewTabProps = {
  isFetchingData: boolean;
  collapsed: boolean;
  name: string;
  label: string;
  onToggleCollapsed: () => void;
};

export default function OverviewTab({
  isFetchingData,
  collapsed,
  name,
  label,
  onToggleCollapsed,
}: OverviewTabProps) {
  const collapsedRef = useRef(collapsed);
  const { searchParams, pushParams, removeParams } = usePath();
  const [activeConversationID, setActiveConversationID] = useState<number>();

  useEffect(() => {
    if (!searchParams.get('activeConversationID')) return;
    const convoID = parseInt(searchParams.get('activeConversationID')!);
    if (convoID !== activeConversationID) {
      handleSelectConversation(convoID);
    }
  }, [searchParams]);

  const handleSelectConversation = (conversationID: number) => {
    if (!collapsed) onToggleCollapsed();
    setActiveConversationID(conversationID);
    pushParams({ activeConversationID: conversationID });
  };

  const handleCloseConversation = () => {
    // Re-open timeline if it was originally open
    if (collapsed && !collapsedRef.current) onToggleCollapsed();
    setActiveConversationID(undefined);
    removeParams(['activeConversationID']);
  };

  return (
    <Tab name={name} label={label} className={classes.tab}>
      {activeConversationID ? (
        <Card className={classes.card}>
          <Conversation
            conversationID={activeConversationID}
            onClose={handleCloseConversation}
          />
        </Card>
      ) : (
        <>
          <TasksSection isFetchingData={isFetchingData} />
          <Divider />
          <ConversationsSection
            isFetchingData={isFetchingData}
            onSelectConversation={handleSelectConversation}
          />
        </>
      )}
    </Tab>
  );
}
