import CloseIcon from '@/icons/CloseIcon';
import Title from '@/components/Title';
import { classes } from './utils';

export type InfoHeaderProps = {
  onClose: () => void;
};

export default function InfoHeader({ onClose }: InfoHeaderProps) {
  return (
    <div className={classes.header}>
      <Title size="xs">Details</Title>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
    </div>
  );
}
