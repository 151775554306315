export const classes = {
  titleWrapper: 'flex justify-between items-center px-4 py-2',
  list: 'h-[225px] max-h-[80vh] overflow-y-auto px-3',
  listItem:
    'cursor-pointer select-none text-grey-8 py-0 hover:bg-grey-2 pl-3 pr-4 py-1 my-1 rounded-lg',
  checkbox: 'pt-0 pb-0 mr-2',
  search: 'mb-2',
};

export type FilterOption<T> = { value: T; label: string };
