import { theme } from '@/utils/theme';

export const classes = {
  formControl: 'w-full',
  icon: 'mb-1 mr-2 text-grey-6',
  iconButton: 'mb-1 hover:bg-transparent text-grey-6 hover:text-purple-2',
};

export const styles = {
  formControl: {
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: `1px solid ${theme.palette['purple-2'].main}`,
    },
    '& .MuiInputBase-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
      {
        transition: 'border-color 0.2s',
        borderColor: theme.palette['purple-2'].main,
      },
  },
};
