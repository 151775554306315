export const classes = {
  sidebar:
    'h-full shadow-sidebar px-5 py-6 bg-white overflow-auto flex flex-col justify-between',
  open: 'w-[15rem]',
  collapsed: 'w-[6rem]',
  iconWrapper: 'flex justify-start px-4',
  menuIcon: 'text-[2rem] cursor-pointer text-grey-6',
};

export const styles = {
  icon: {
    position: 'relative',
    left: '0rem',
    fontSize: '1.5rem',
  },
  sidebar: { transition: 'width 0.15s' },
  menuIcon: (isCollapsed?: boolean) => ({
    transform: `rotateY(${isCollapsed ? '180deg' : '0deg'})`,
    transition: 'transform 0.15s',
  }),
};

export const ICON_FONT_SIZE = '1.5rem';
