import { type DecoratedConversationUser } from '@witmetrics/api-client';
import { sortAlphabeticalByObjectValue } from '@/utils/arrays';

export const classes = {
  wrapper: 'w-full',
};

export function buildMembersList(
  conversationUsers: DecoratedConversationUser[] | null
) {
  if (!conversationUsers) return [];
  return sortAlphabeticalByObjectValue(
    conversationUsers,
    ({ user }) => user.firstname
  );
}
