import { API } from '@/api';

export const classes = {
  wrapper: 'pt-4 pb-5 px-4',
  title: 'flex items-center justify-between',
  leftContent: 'flex items-center justify-start',
  name: 'font-semibold text-base text-grey-8',
  iconButton: 'p-1 icon-button',
  dueDate: 'ml-3',
  description: 'text-sm text-grey-7 mt-2',
};

export const styles = {
  title: {
    '& .icon-button': {
      // opacity: 0,
      opacity: 1,
      transition: 'opacity 0.1s',
    },
    '&:hover .icon-button': {
      opacity: 1,
    },
  },
};

export function updateChecklistDueAt(
  checklistID: number,
  dueAt: string | null
) {
  return API.Checklists.updateChecklist(checklistID, { dueAt });
}
