import { useRef } from 'react';
import Link from 'next/link';
import Menu, { MenuItem } from '@/components/Menu';
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import { useToggle } from '@/hooks/useToggle';
import { PLATFORMS } from '../utils';
import { classes } from './utils';

export default function PlatformSelector() {
  const menuToggleRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  return (
    <div>
      <div ref={menuToggleRef}>
        <div className={classes.wrapper} onClick={() => toggleMenu()}>
          <span className={classes.label}>Unison</span>
          <ArrowDownIcon className={classes.arrowIcon} />
        </div>
      </div>
      <Menu
        open={isMenuOpen}
        anchorTo={menuToggleRef.current}
        onClose={() => toggleMenu()}>
        <Link href={PLATFORMS.CRM}>
          <MenuItem label="CRM" />
        </Link>
        <Link href={PLATFORMS.INSIGHTS}>
          <MenuItem label="Insights" />
        </Link>
        {/*<Link href={PLATFORMS.PERSPECTIVE}>*/}
        {/*  <MenuItem label="Perspective" />*/}
        {/*</Link>*/}
      </Menu>
    </div>
  );
}
