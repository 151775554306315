import { API } from '@/api';

export const classes = {
  button:
    'mt-2 mb-3 text-grey-8 text-base flex justify-start items-center cursor-pointer py-2 pl-2 pr-4 bg-grey-2 rounded-lg hover:bg-grey-3 transition-colors',
  projectStatusCircle: 'mr-2',
};

export function updateProjectStatus(
  projectID: number,
  unisonProjectStatusID: number
) {
  return API.UnisonProjects.updateUnisonProject(projectID, {
    unisonProjectStatusID,
  });
}
