import { useLayoutEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  useConversationMessages,
  useConversationUsers,
} from '@/store/useStore';
import { sortByKey } from '@/utils/arrays';
import Message from './Message';
import { classes, hasFollowUp, isActiveMember, showInfo } from './utils';

export type ConversationMessagesProps = {
  className?: string;
  conversationID: number | null;
};

export default function ConversationMessages({
  className = '',
  conversationID,
}: ConversationMessagesProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const messages = useConversationMessages(conversationID);
  const conversationUsers = useConversationUsers(conversationID);

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, []);

  const orderedMessages = sortByKey(messages, 'sentAt', -1);

  return (
    <div ref={wrapperRef} className={twMerge(classes.wrapper, className)}>
      {orderedMessages.map((message, i) => (
        <Message
          key={message.id}
          isActiveMember={isActiveMember(message, conversationUsers)}
          showInfo={showInfo(orderedMessages, i)}
          hasFollowUp={hasFollowUp(orderedMessages, i)}
          messageID={message.id}
        />
      ))}
    </div>
  );
}
