import { API } from '@/api';
import {
  DecoratedConversationUser,
  UndecoratedUser,
  USER_CONVERSATION_STATUS_ID,
} from '@witmetrics/api-client';
import { isBlank, renderUserName } from '@/utils/strings';
import { mapToID, mapToKey } from '@/utils/arrays';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full h-[70vh] max-h-[750px] min-h-[100px] pt-2 pb-12 overflow-auto',
  title: 'text-base font-medium text-grey-8',
  divider: 'my-4',
  list: 'max-h-[250px] overflow-y-auto',
  search: 'w-full my-4',
  message: 'text-base text-grey-7 text-center py-4',
};

export async function fetchPracticeUsers(accountID: number) {
  const users = await API.Practices.fetchPracticeUsers(accountID, {
    filters: [],
    pagination: {
      page: 1,
      pageSize: 100, // NOTE: Should be large enough to fetch them all
    },
  });
  return users.results.map(({ user }) => user);
}

export function saveNewMembers(
  conversationID: number,
  newMembers: UndecoratedUser[]
) {
  return API.Conversations.addConversationUsers(
    conversationID,
    newMembers.map(({ id }) => ({
      userID: id,
      userConversationStatusID: USER_CONVERSATION_STATUS_ID.MEMBER,
    }))
  );
}

export function getAvailableUsers(
  practiceUsers: UndecoratedUser[],
  currentMembers: DecoratedConversationUser[] | null,
  newMembers: UndecoratedUser[]
) {
  const members = currentMembers ? mapToKey(currentMembers, 'user') : [];
  const options = mapToID([...members, ...newMembers]);
  return practiceUsers.filter((u) => !options.includes(u.id));
}

export function searchUsers(users: UndecoratedUser[], search: string) {
  if (isBlank(search)) return users;
  const query = search.toLowerCase().trim();
  return users.filter((u) => renderUserName(u).toLowerCase().includes(query));
}
