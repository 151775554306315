import { useState } from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import IconButton from '@/components/IconButton';
import SendIcon from '@/icons/SendIcon';
import FileIcon from '@/icons/FileIcon';
import FolderIcon from '@/icons/FolderIcon';
import AttachmentButton from './AttachmentButton';
import Spinner from '@/components/Spinner';
import Attachment from './Attachment';
import { useToggle } from '@/hooks/useToggle';
import { isBlank } from '@/utils/strings';
import { useAppState } from '@/providers/AppStateProvider';
import { generateUUID } from '@/utils/uuid';
import { type Directory } from '@/utils/files';
import { useDispatch } from '@/store/useStore';
import { addConversationMessage as dispatchAddConversationMessage } from '@/store/slices/conversationMessagesSlice';
import {
  ATTACHMENT_TYPES,
  classes,
  type MessageAttachment,
  type NewDirectory,
  type NewFile,
  sendMessage,
  uploadNewAttachments,
} from './utils';

export type MessageComposerProps = {
  disabled?: boolean;
  projectID: number;
  conversationID: number;
};

export default function MessageComposer({
  disabled,
  projectID,
  conversationID,
}: MessageComposerProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<MessageAttachment[]>([]);
  const [isSending, toggleSending] = useToggle(false);

  const handleSend = async () => {
    try {
      toggleSending(true);
      if (attachments.length > 0) {
        const fileCollection = await uploadNewAttachments(
          projectID,
          attachments
        );
        const mediaMessage = await sendMessage(conversationID, '', [
          fileCollection.id,
        ]);
        dispatch(dispatchAddConversationMessage(mediaMessage));
      }
      if (!isBlank(message)) {
        const textMessage = await sendMessage(conversationID, message);
        dispatch(dispatchAddConversationMessage(textMessage));
      }
      toggleSending(false);
      setAttachments([]);
      setMessage('');
    } catch (err) {
      toggleSending(false);
      onApiError(err, 'Error sending message');
    }
  };

  const handleSelectFiles = (files: File[]) => {
    setAttachments([
      ...attachments,
      ...files.map((file) => ({
        uuid: generateUUID(),
        type: ATTACHMENT_TYPES.NEW_FILE,
        file,
      })),
    ]);
  };

  const handleSelectDirectory = (directory: Directory) => {
    setAttachments([
      ...attachments,
      {
        uuid: generateUUID(),
        type: ATTACHMENT_TYPES.NEW_DIRECTORY,
        directory,
      },
    ]);
  };

  const handleRemoveAttachment = (uuid: string) => {
    setAttachments(attachments.filter((a) => a.uuid !== uuid));
  };

  const renderNewFileAttachment = (newFile: NewFile) => {
    return (
      <Attachment
        key={newFile.uuid}
        name={newFile.file.name}
        renderIcon={(iconClassName) => <FileIcon className={iconClassName} />}
        onRemove={() => handleRemoveAttachment(newFile.uuid)}
      />
    );
  };

  const renderNewDirectoryAttachment = (newDirectory: NewDirectory) => {
    const name = Object.keys(newDirectory.directory)[0];
    return (
      <Attachment
        key={newDirectory.uuid}
        name={name}
        renderIcon={(iconClassName) => <FolderIcon className={iconClassName} />}
        onRemove={() => handleRemoveAttachment(newDirectory.uuid)}
      />
    );
  };

  const renderAttachment = (attachment: MessageAttachment) => {
    switch (attachment.type) {
      case ATTACHMENT_TYPES.NEW_FILE:
        return renderNewFileAttachment(attachment);
      case ATTACHMENT_TYPES.NEW_DIRECTORY:
        return renderNewDirectoryAttachment(attachment);
      default:
        return null;
    }
  };

  return (
    <div className={classes.wrapper}>
      {attachments.length > 0 && (
        <div className={classes.attachmentsWrapper}>
          {attachments.map(renderAttachment)}
        </div>
      )}
      <TextareaAutosize
        autoComplete="off"
        maxRows={6}
        placeholder="Send a message..."
        className={classes.textarea}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className={classes.controlsWrapper}>
        <AttachmentButton
          projectID={projectID}
          onAddFiles={handleSelectFiles}
          onAddDirectory={handleSelectDirectory}
          onAddProjectFiles={() => null}
          onAddProjectFileCollections={() => null}
        />
        {isSending ? (
          <Spinner size={20} />
        ) : (
          <IconButton
            disabled={
              disabled || (isBlank(message) && attachments.length === 0)
            }
            onClick={handleSend}>
            <SendIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
