import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  useConversationMessage,
  useConversationMessageAttachments,
  useCurrentUser,
  useDateFormatPreference,
} from '@/store/useStore';
import ProfilePicture from '@/components/ProfilePicture';
import { useToggle } from '@/hooks/useToggle';
import FileViewerDialog from '@/dialogs/FileViewerDialog';
import FileCollectionViewerDialog from '@/dialogs/FileCollectionViewerDialog';
import FileIcon from '@/icons/FileIcon';
import FolderIcon from '@/icons/FolderIcon';
import Attachment from '../Attachment';
import { useAppState } from '@/providers/AppStateProvider';
import { fetchFileVersion, openFileDownloadLink } from '@/utils/files';
import { showErrorToaster } from '@/utils/toasters';
import DownloadToaster from '@/components/DownloadToaster';
import { classes, getNameString, getSentAtString } from './utils';

export type MessageProps = {
  messageID: number;
  isActiveMember: boolean;
  showInfo?: boolean;
  hasFollowUp?: boolean;
};

export default function Message({
  messageID,
  isActiveMember,
  showInfo,
  hasFollowUp,
}: MessageProps) {
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const dateFormat = useDateFormatPreference();
  const [activeFileID, setActiveFileID] = useState<number>();
  const [activeCollectionID, setActiveCollectionID] = useState<number>();
  const [isFilePreviewShown, toggleFilePreview] = useToggle(false);
  const [isCollectionPreviewShown, toggleCollectionPreview] = useToggle(false);
  const [isDownloadToasterOpen, toggleDownloadToaster] = useToggle(false);
  const [isPreparingDownload, togglePreparingDownload] = useToggle(false);
  const message = useConversationMessage(messageID);
  const { files, fileCollections } =
    useConversationMessageAttachments(messageID);

  if (!message || !currentUser) return null;

  const isSender = message.createdBy?.id === currentUser.id;

  const handleFileDownload = async (fileID: number) => {
    try {
      setActiveFileID(fileID);
      togglePreparingDownload(true);
      toggleDownloadToaster(true);
      const selectedFile = files.find((f) => f.id === fileID)!;
      const fileVersion = await fetchFileVersion(fileID);
      if (!fileVersion) return showErrorToaster('File version not found');
      await openFileDownloadLink(fileVersion.id, selectedFile.name);
      togglePreparingDownload(false);
      setActiveFileID(undefined);
    } catch (err) {
      handleCloseDownloadToaster();
      onApiError(err, 'Error downloading file');
    }
  };

  const handleCloseDownloadToaster = () => {
    togglePreparingDownload(false);
    toggleDownloadToaster(false);
  };

  const handlePreviewFile = (fileID: number) => {
    setActiveFileID(fileID);
    toggleFilePreview(true);
  };

  const handleCloseFilePreview = () => {
    toggleFilePreview(false);
    setActiveFileID(undefined);
  };

  const handlePreviewCollection = (fileCollectionID: number) => {
    handleCloseFilePreview();
    setActiveCollectionID(fileCollectionID);
    toggleCollectionPreview(true);
  };

  const handleCloseCollectionPreview = () => {
    toggleCollectionPreview(false);
    setActiveCollectionID(undefined);
  };

  const renderContent = () => {
    const contentClassName = twMerge(
      classes.messageBlock,
      isSender ? classes.selfBlock : classes.otherBlock
    );
    return (
      <>
        {(files.length > 0 || fileCollections.length > 0) && (
          <div
            className={twMerge(
              classes.attachmentsBlock,
              isSender ? classes.selfAttachments : classes.otherAttachments
            )}>
            {fileCollections.map((fileCollection) => (
              <Attachment
                key={fileCollection.id}
                name={fileCollection.name}
                renderIcon={(iconClassName) => (
                  <FolderIcon className={iconClassName} />
                )}
                onOpen={() => handlePreviewCollection(fileCollection.id)}
              />
            ))}
            {files.map((file) => (
              <Attachment
                key={file.id}
                name={file.name}
                renderIcon={(iconClassName) => (
                  <FileIcon className={iconClassName} />
                )}
                onDownload={() => handleFileDownload(file.id)}
                onOpen={() => handlePreviewFile(file.id)}
              />
            ))}
          </div>
        )}
        {message.content.length > 0 && (
          <div className={contentClassName}>{message.content}</div>
        )}
        {isFilePreviewShown && activeFileID && (
          <FileViewerDialog
            fileID={activeFileID}
            onClose={handleCloseFilePreview}
          />
        )}
        {isCollectionPreviewShown && activeCollectionID && (
          <FileCollectionViewerDialog
            fileCollectionID={activeCollectionID}
            onClose={handleCloseCollectionPreview}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={twMerge(
          classes.wrapper,
          isSender && classes.selfWrapper,
          !isActiveMember && classes.inactive,
          !showInfo && classes.shortTop,
          hasFollowUp && classes.shortBottom
        )}>
        <div
          className={twMerge(
            classes.avatarWrapper,
            !showInfo && classes.hidden
          )}>
          <ProfilePicture
            className={classes.avatar}
            size={30}
            user={message.createdBy}
          />
        </div>
        <div
          className={twMerge(
            classes.contentWrapper,
            isSender ? classes.selfContent : classes.otherContent
          )}>
          {showInfo && (
            <div className={classes.topRow}>
              {!isSender && (
                <span className={classes.name}>
                  {getNameString(message.createdBy, isActiveMember)}
                </span>
              )}
              <span className={classes.sentAt}>
                {getSentAtString(message.sentAt, dateFormat)}
              </span>
            </div>
          )}
          {renderContent()}
        </div>
      </div>
      <DownloadToaster
        open={isDownloadToasterOpen}
        isLoading={isPreparingDownload}
        onClose={handleCloseDownloadToaster}
      />
    </>
  );
}
