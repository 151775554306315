export const classes = {
  wrapper: 'bg-grey-2',
  newCommentWrapper: 'bg-grey-2 px-2 py-2',
};

export const styles = {
  list: {
    '&': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
