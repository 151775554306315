import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import Drawer from '@mui/material/Drawer';
import { styles } from './utils';

export type OverlayProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  onClose: () => void;
};

export default function Overlay({ onClose, ...props }: OverlayProps) {
  return (
    <Drawer open anchor="left" sx={styles.drawer} onClose={onClose}>
      <div {...props} />
    </Drawer>
  );
}
