import { type ReactNode, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '@mui/material/Button';
import FilterIcon from '@/icons/FilterIcon';
import Badge from '@mui/material/Badge';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type SectionTitleProps = {
  title: string;
  newLabel?: string;
  filtersCount?: number;
  onAddNew?: () => void;
  renderSearchBar?: () => ReactNode;
  renderFilterMenu?: (settings: {
    open: boolean;
    anchorTo: HTMLElement | null;
    onClose: () => void;
  }) => ReactNode;
};

export default function SectionTitle({
  title,
  newLabel = 'Add new',
  filtersCount = 0,
  renderFilterMenu,
  renderSearchBar,
  onAddNew,
}: SectionTitleProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <h1 className={classes.title}>{title}</h1>
        {renderFilterMenu && (
          <>
            <Badge
              className={
                filtersCount > 0 ? classes.activeBadge : classes.inactiveBadge
              }
              // @ts-ignore -- MUI not recognizing custom palette
              color="purple-2"
              badgeContent={filtersCount}>
              <FilterIcon
                ref={menuToggleRef}
                className={twMerge(
                  classes.iconButton,
                  filtersCount > 0 && classes.activeFilters
                )}
                onClick={() => toggleMenu()}
              />
            </Badge>
            {renderFilterMenu({
              open: isMenuOpen,
              anchorTo: menuToggleRef.current,
              onClose: () => toggleMenu(),
            })}
          </>
        )}
      </div>
      {renderSearchBar ? renderSearchBar() : null}
      {onAddNew ? (
        <Button
          // @ts-ignore
          color="purple-2"
          className={classes.addButton}
          onClick={onAddNew}>
          {newLabel}
        </Button>
      ) : null}
    </div>
  );
}
