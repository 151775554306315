import { theme } from '@/utils/theme';
import type { DecoratedChecklist } from '@witmetrics/api-client';

export const classes = {
  list: 'p-0 pb-4',
  skeleton: 'py-8 w-full',
  dropPlaceholder:
    'mx-3 my-2 py-3 pl-4 pr-3 rounded-lg bg-white border border-grey-4 border-dashed',
};

export const styles = {
  list: {
    '& .js-dragging-item .js-draggable': {
      backgroundColor: theme.palette['grey-2'].main,
    },
  },
  skeletonItem: {
    '&': { padding: 0 },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette['grey-3'].main}`,
    },
  },
  skeleton: (delay: number) => ({
    '&:after': {
      animationDelay: `${delay}s`,
      animationDuration: '1.2s',
    },
  }),
};

export function buildItemsList(
  checklist: DecoratedChecklist | null,
  showComplete: boolean
) {
  if (!checklist?.items) return [];
  if (showComplete) return checklist.items;
  return checklist.items.filter((item) => !item.isComplete);
}
