import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import Title from '@/components/Title';
import { classes } from './utils';

export interface FormBlockProps extends PropsWithChildren {
  className?: string;
  title: string;
  subtitle?: string;
}

export default function FormBlock({
  className,
  title,
  subtitle,
  children,
}: FormBlockProps) {
  return (
    <div className={twMerge(classes.section, className)}>
      <div className={classes.leftCol}>
        <Title>{title}</Title>
        {subtitle ? <p className={classes.subtitle}>{subtitle}</p> : null}
      </div>
      <div className={classes.rightCol}>{children}</div>
    </div>
  );
}
