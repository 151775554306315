import { useAppState } from '@/providers/AppStateProvider';
import AgendaOverlay from '@/overlays/AgendaOverlay';
import { AGENDA } from '@/constants/overlays';

export default function OverlaysManager() {
  const { activeOverlays, onCloseOverlay } = useAppState();

  return (
    <>
      {activeOverlays[AGENDA] && (
        <AgendaOverlay onClose={() => onCloseOverlay(AGENDA)} />
      )}
    </>
  );
}
