import { type MouseEventHandler, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  useDateFormatPreference,
  useWeekStartsOnPreference,
} from '@/store/useStore';
import ButtonBase from '@mui/material/ButtonBase';
import TimeIcon from '@/icons/TimeIcon';
import CloseIcon from '@/icons/CloseIcon';
import DateTimePopover from '@/components/DateTimePopover';
import { useToggle } from '@/hooks/useToggle';
import { renderRelativeDate } from '@/utils/time';
import { classes, getDueDateClasses, getIconColor, styles } from './utils';

export type DueDateProps = {
  isPending?: boolean;
  className?: string;
  value?: string | null;
  onChange: (date: string | null) => void;
};

export default function DueDate({
  isPending,
  className = '',
  value,
  onChange,
}: DueDateProps) {
  const menuToggleRef = useRef(null);
  const dateFormat = useDateFormatPreference();
  const weekStartsOn = useWeekStartsOnPreference();
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleChange = (update: { dueAt: Date }) => {
    onChange(update.dueAt.toISOString());
  };

  const handleClear: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(null);
  };

  const renderContent = () => {
    if (!value) return null;
    let content = isPending
      ? 'Saving...'
      : renderRelativeDate(value, { dateFormat });
    return <span className={classes.content}>{content}</span>;
  };

  return (
    <>
      <ButtonBase
        ref={menuToggleRef}
        sx={styles.button}
        className={twMerge(
          classes.wrapper,
          getDueDateClasses(value),
          className
        )}
        onClick={() => toggleMenu()}>
        <TimeIcon
          className={classes.timeIcon}
          // @ts-ignore -- MUI not recognizing custom palette
          color={getIconColor(value)}
        />
        {renderContent()}
        {value && (
          <CloseIcon
            className={classes.closeIcon}
            // @ts-ignore -- MUI not recognizing custom palette
            color="grey-8"
            onClick={handleClear}
          />
        )}
      </ButtonBase>
      {isMenuOpen && (
        <DateTimePopover
          open
          closeOnSelect
          disablePast={false}
          showTime={false}
          weekStartsOn={weekStartsOn}
          name="dueAt"
          anchorTo={menuToggleRef.current}
          onChange={handleChange}
          onClose={() => toggleMenu(false)}
        />
      )}
    </>
  );
}
