import { useState } from 'react';
import { usePracticeUsers } from '@/store/useStore';
import Menu from '@/components/Menu';
import Divider from '@/components/Divider';
import Button from '@/components/Button';
import FiltersList from '@/components/FiltersList';
import { sortAlphabeticalByKey } from '@/utils/arrays';
import {
  ALL_STATUSES,
  classes,
  FILTER_OPTIONS,
  formatUserOption,
} from './utils';

export type TasksFilterMenuProps = {
  open: boolean;
  anchorTo: HTMLElement | null;
  statusFilters: boolean[];
  userFilters: number[];
  onApplyFilters: (filters: {
    statusFilters: boolean[];
    userFilters: number[];
  }) => void;
  onClose: () => void;
};

export default function TasksFilterMenu({
  open,
  anchorTo,
  statusFilters,
  userFilters,
  onApplyFilters,
  onClose,
}: TasksFilterMenuProps) {
  const practiceUsers = usePracticeUsers();
  const [statuses, setStatuses] = useState(statusFilters);
  const [users, setUsers] = useState(userFilters);

  const handleApply = () => {
    onApplyFilters({ statusFilters: statuses, userFilters: users });
    onClose();
  };

  const handleReset = () => {
    const statusFilters = ALL_STATUSES;
    const userFilters = practiceUsers.map((u) => u.userID);
    setStatuses(statusFilters);
    setUsers(userFilters);
    onApplyFilters({ statusFilters, userFilters });
    onClose();
  };

  const buildUserList = () => {
    return sortAlphabeticalByKey(practiceUsers.map(formatUserOption), 'label');
  };

  return (
    <Menu open={open} anchorTo={anchorTo} onClose={onClose}>
      <div className={classes.filtersWrapper}>
        <FiltersList
          className={classes.filtersList}
          name="statuses"
          title="Status"
          selected={statuses}
          options={FILTER_OPTIONS}
          onChange={(update) => setStatuses(update.statuses)}
        />
        <Divider orientation="vertical" flexItem />
        <FiltersList
          filter
          className={classes.filtersList}
          name="assignedTo"
          title="Assigned To"
          selected={users}
          options={buildUserList()}
          onChange={(update) => setUsers(update.assignedTo)}
        />
      </div>
      <Divider />
      <div className={classes.buttonsWrapper}>
        <Button
          variant="text"
          className={classes.resetButton}
          onClick={handleReset}>
          Reset
        </Button>
        <Button className={classes.applyButton} onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Menu>
  );
}
