import { useRef } from 'react';
import { useChecklist, useDispatch } from '@/store/useStore';
import { updateChecklist as dispatchUpdateChecklist } from '@/store/slices/checklistsSlice';
import { useToggle } from '@/hooks/useToggle';
import Box from '@mui/material/Box';
import DefaultOptionsMenu, {
  MenuOption,
} from '@/components/DefaultOptionsMenu';
import DueDate from '@/components/DueDate';
import IconButton from '@/components/IconButton';
import OptionsIcon from '@/icons/OptionsIcon';
import ShowIcon from '@/icons/ShowIcon';
import HideIcon from '@/icons/HideIcon';
import ChecklistDialog from '@/dialogs/ChecklistDialog';
import { useAppState } from '@/providers/AppStateProvider';
import { showSuccessToaster } from '@/utils/toasters';
import { classes, styles, updateChecklistDueAt } from './utils';

export type ChecklistCardHeaderProps = {
  showComplete: boolean;
  checklistID: number;
  onToggleComplete: (showComplete: boolean) => void;
  onDelete: () => void;
};

export default function ChecklistCardHeader({
  checklistID,
  showComplete,
  onToggleComplete,
  onDelete,
}: ChecklistCardHeaderProps) {
  const menuToggleRef = useRef(null);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const checklist = useChecklist(checklistID);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isUpdatingDueAt, toggleUpdatingDueAt] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);

  if (!checklist) return null;

  const handleDueAtChange = async (dueAt: string | null) => {
    try {
      toggleUpdatingDueAt(true);
      const updatedChecklist = await updateChecklistDueAt(checklistID, dueAt);
      dispatch(dispatchUpdateChecklist(updatedChecklist));
      toggleUpdatingDueAt(false);
      showSuccessToaster('Due date updated');
    } catch (err) {
      toggleUpdatingDueAt(false);
      onApiError(err, 'Error updating due date');
    }
  };

  const handleToggleHideComplete = () => {
    onToggleComplete(!showComplete);
    toggleMenu(false);
  };

  const handleDelete = () => {
    onDelete();
    toggleMenu(false);
  };

  const handleEdit = () => {
    toggleMenu(false);
    toggleEditing(true);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Box sx={styles.title} className={classes.title}>
          <div className={classes.leftContent}>
            <div className={classes.name}>{checklist.name}</div>
            <DueDate
              isPending={isUpdatingDueAt}
              className={classes.dueDate}
              value={checklist.dueAt}
              onChange={handleDueAtChange}
            />
          </div>
          <IconButton
            ref={menuToggleRef}
            className={classes.iconButton}
            onClick={() => toggleMenu()}>
            <OptionsIcon
              // @ts-ignore -- MUI not recognizing custom palette
              color="grey-6"
            />
          </IconButton>
        </Box>
        {checklist.description?.length > 0 && (
          <div className={classes.description}>{checklist.description}</div>
        )}
      </div>
      {isMenuOpen && (
        <DefaultOptionsMenu
          open
          anchorTo={menuToggleRef.current}
          extraOptions={[
            <MenuOption
              key="isCompleteHidden"
              label={
                showComplete ? 'Hide completed items' : 'Show completed items'
              }
              icon={showComplete ? <HideIcon /> : <ShowIcon />}
              onClick={handleToggleHideComplete}
            />,
          ]}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={() => toggleMenu(false)}
        />
      )}
      {isEditing && (
        <ChecklistDialog
          checklistID={checklistID}
          onClose={() => toggleEditing(false)}
        />
      )}
    </>
  );
}
