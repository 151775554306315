import {
  type DecoratedConversationMessage,
  type DecoratedConversationUser,
} from '@witmetrics/api-client';
import { differenceInMinutes } from '@/utils/time';

export const classes = {
  wrapper: 'grow bg-white min-h-0 overflow-y-auto py-6',
};

export function isActiveMember(
  message: DecoratedConversationMessage,
  conversationUsers: DecoratedConversationUser[] | null
) {
  if (!conversationUsers) return false;
  return conversationUsers.some(({ user }) => user.id === message.createdBy.id);
}

export function showInfo(
  messages: DecoratedConversationMessage[],
  index: number
) {
  if (index === 0) return true;
  // Doesn't have a message sent less than 2 minutes before this one
  const lastMessage = messages[index - 1];
  return (
    !isSameAuthor(messages[index], lastMessage) ||
    differenceInMinutes(lastMessage.sentAt, messages[index].sentAt) > 2
  );
}

export function hasFollowUp(
  messages: DecoratedConversationMessage[],
  index: number
) {
  const nextMessage = messages[index + 1];
  if (!nextMessage) return false;
  // Has a message sent less than 2 minutes after this one
  return (
    isSameAuthor(messages[index], nextMessage) &&
    differenceInMinutes(messages[index].sentAt, nextMessage.sentAt) <= 2
  );
}

function isSameAuthor(
  messageA: DecoratedConversationMessage,
  messageB: DecoratedConversationMessage
) {
  return messageA.createdBy.id === messageB.createdBy.id;
}
