import { SORT_ORDER } from '@witmetrics/api-client';
import { API } from '@/api';

export const classes = {
  overlay: 'flex w-[96vw] max-w-[1450px]',
};

const defaultParams = {
  filters: [],
  pagination: { page: 1, pageSize: 100 },
  sorting: { sortBy: 'updatedAt', sortOrder: SORT_ORDER.DESCENDING },
};

export async function fetchProjectData(projectID: number, practiceID: number) {
  const data = await Promise.all([
    API.UnisonProjects.fetchUnisonProject(projectID),
    fetchProjectChecklists(projectID),
    fetchProjectTasks(projectID),
    fetchProjectConversations(projectID),
    fetchProjectNotes(projectID),
    fetchPracticeUsers(practiceID),
  ]);
  return {
    unisonProject: data[0],
    checklists: data[1],
    tasks: data[2],
    conversations: data[3],
    notes: data[4],
    practiceUsers: data[5],
  };
}

async function fetchProjectTasks(projectID: number) {
  const { results } = await API.UnisonProjects.fetchUnisonProjectTasks(
    projectID,
    defaultParams
  );
  return results;
}

async function fetchProjectChecklists(projectID: number) {
  const { results } = await API.UnisonProjects.fetchUnisonProjectChecklists(
    projectID,
    defaultParams
  );
  return results;
}

async function fetchProjectConversations(projectID: number) {
  const { results } = await API.UnisonProjects.fetchUnisonProjectConversations(
    projectID,
    defaultParams
  );
  return results;
}

async function fetchProjectNotes(projectID: number) {
  const { results } = await API.UnisonProjects.fetchUnisonProjectNotes(
    projectID,
    defaultParams
  );
  return results;
}

async function fetchPracticeUsers(practiceID: number) {
  const { results } = await API.Practices.fetchPracticeUsers(practiceID, {
    filters: [],
    pagination: { page: 1, pageSize: 100 },
  });
  return results;
}
