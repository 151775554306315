'use client';
import Tooltip from '@/components/Tooltip';
import ProjectStatus from '@/components/ProjectStatus';
import PersonIcon from '@/icons/PersonIcon';
import MenuIcon from '@/icons/MenuIcon';
import { usePath } from '@/hooks/usePath';
import { useUnisonProject } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { renderUserName } from '@/utils/strings';
import { getCRMURL } from '@/api/getURL';
import { classes, styles } from './utils';

export type ProjectHeaderProps = {
  collapsed: boolean;
  onToggleCollapsed: () => void;
};

export default function LeadHeader({
  collapsed,
  onToggleCollapsed,
}: ProjectHeaderProps) {
  const { activeProjectID } = useAppState();
  const { navigate } = usePath();
  const project = useUnisonProject(activeProjectID);

  const handleGoToCRM = () => {
    return navigate(`${getCRMURL()}/dashboard/overview`, {
      activeLeadID: project!.user!.id,
    });
  };

  const renderCollapseToggle = () => {
    return (
      <Tooltip title={collapsed ? 'Show timeline' : 'Hide timeline'}>
        <MenuIcon
          className={classes.icon}
          style={styles.collapseIcon(collapsed)}
          onClick={onToggleCollapsed}
        />
      </Tooltip>
    );
  };

  if (!project) return null;

  if (collapsed) {
    return <div className={classes.wrapper}>{renderCollapseToggle()}</div>;
  }

  return (
    <div className={classes.wrapper}>
      {renderCollapseToggle()}
      <div className={classes.textWrapper}>
        <h1 className={classes.header}>{project.name}</h1>
        <ProjectStatus className={classes.status} project={project} />
        {project.user && (
          <Tooltip title="Open in CRM" enterDelay={0}>
            <button className={classes.button} onClick={handleGoToCRM}>
              <PersonIcon className={classes.person} />
              <span>{renderUserName(project.user)}</span>
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
