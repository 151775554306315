import { useEffect, useState } from 'react';
import { useDispatch, useUnisonProject } from '@/store/useStore';
import {
  addUnisonProject as dispatchAddUnisonProject,
  updateUnisonProject as dispatchUpdateUnisonProject,
} from '@/store/slices/unisonProjectsSlice';
import { addUnisonProjectNote as dispatchAddUnisonProjectNote } from '@/store/slices/unisonProjectNotesSlice';
import { addNote as dispatchAddNote } from '@/store/slices/notesSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import LeadDialog from '@/dialogs/LeadDialog';
import FormBlock from '@/components/FormBlock';
import TextField from '@/components/TextField';
import SearchableDropdown, {
  type SearchableDropdownOption,
} from '@/components/SearchableDropdown';
import { showSuccessToaster, showWarningToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { buildUserOption } from '@/utils/filters';
import type { DecoratedLead } from '@witmetrics/api-client';
import {
  classes,
  createUnisonProject,
  searchLeads,
  updateUnisonProject,
  validateFields,
} from './utils';

export type ProjectDialogProps = {
  projectID?: number;
  onClose: () => void;
};

export default function ProjectDialog({
  projectID,
  onClose,
}: ProjectDialogProps) {
  const dispatch = useDispatch();
  const { onSelectProject, onApiError } = useAppState();
  const project = useUnisonProject(projectID);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [initialFirstname, setInitialFirstname] = useState('');
  const [lead, setLead] = useState<SearchableDropdownOption<number> | null>(
    null
  );
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isLeadDialogOpen, toggleLeadDialog] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (project) fillFields();
  }, [project]);

  const fillFields = () => {
    setName(project!.name);
    setDescription(project!.description);
    if (project?.user) setLead(buildUserOption(project.user));
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const userID = lead?.value;
      const invalidFields = validateFields({ name, userID });
      if (invalidFields.length > 0) {
        showWarningToaster('Please enter a project name or select a lead');
        toggleSaving(false);
        return setInvalidFields(invalidFields);
      }
      const data = { name, description, userID };
      // Have the project name default to the lead's name if it's empty
      if (lead && name.length === 0) data.name = lead.label;
      if (projectID) {
        const updatedProject = await updateUnisonProject(projectID, data);
        dispatch(dispatchUpdateUnisonProject(updatedProject));
        showSuccessToaster('Project updated');
      } else {
        const result = await createUnisonProject(data, notes.trim());
        dispatch(dispatchAddUnisonProject(result.project));
        if (result.note) dispatch(dispatchAddNote(result.note));
        if (result.projectNote) {
          dispatch(dispatchAddUnisonProjectNote(result.projectNote));
        }
        showSuccessToaster('Project created');
        toggleSaving(false);
        onSelectProject(result.project.id);
      }
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving project', () => handleSave());
    }
  };

  const handleStartNewLead = (search: string) => {
    setInitialFirstname(search);
    toggleLeadDialog(true);
  };

  const handleSelectNewLead = (lead: DecoratedLead) => {
    setLead(buildUserOption(lead));
    toggleLeadDialog(false);
  };

  const isSaveDisabled = () => {
    return isSaving || validateFields({ name, userID: lead?.value }).length > 0;
  };

  return (
    <>
      <Dialog className={classes.dialog} onClose={onClose}>
        <DialogTitle
          title={projectID ? 'Edit Project' : 'New Project'}
          onClose={onClose}
        />
        <DialogBody className={classes.body}>
          <FormBlock
            title="Name"
            subtitle="Select a lead and/or provide a name for the project">
            <div className={classes.section}>
              <SearchableDropdown<'lead', number>
                allowNew
                autoFocus
                className={classes.userInput}
                name="lead"
                label="Select a Lead"
                value={lead}
                initialOptions={lead ? [lead] : []}
                onSearch={searchLeads}
                onAddNew={handleStartNewLead}
                onSelect={(update) => setLead(update.lead)}
              />
              <TextField
                error={invalidFields.includes('name')}
                className={classes.textField}
                name="name"
                label="Name"
                value={name}
                onChange={(update) => setName(update.name)}
              />
            </div>
          </FormBlock>
          <FormBlock
            title="Details"
            subtitle="Provide additional information about the project (optional)">
            <div className={classes.section}>
              <TextField
                className={classes.textField}
                name="description"
                label="Description"
                value={description}
                onChange={(update) => setDescription(update.description)}
              />
              {!projectID && (
                <TextField
                  multiline
                  className={classes.notes}
                  minRows={4}
                  maxRows={8}
                  name="notes"
                  label="Notes"
                  value={notes}
                  onChange={(update) => setNotes(update.notes)}
                />
              )}
            </div>
          </FormBlock>
        </DialogBody>
        <DefaultDialogActions
          disabled={isSaveDisabled()}
          pending={isSaving}
          confirmLabel="Save"
          pendingLabel="Saving..."
          onCancel={onClose}
          onConfirm={handleSave}
        />
      </Dialog>
      {isLeadDialogOpen && (
        <LeadDialog
          initialFirstname={initialFirstname}
          onClose={() => toggleLeadDialog(false)}
          onSelect={handleSelectNewLead}
        />
      )}
    </>
  );
}
