export const classes = {
  wrapper: 'pb-4 flex items-center justify-between',
  content: 'flex items-center',
  title: 'mr-1 text-xl text-grey-7 font-semibold',
  addButton: 'normal-case font-semibold',
  activeBadge: 'mr-4',
  inactiveBadge: 'mr-3',
  iconButton:
    'transition-all cursor-pointer text-grey-6 hover:text-grey-7 ml-3',
  activeFilters: 'active-filters text-purple-2 hover:text-purple-3',
};
