import { useState } from 'react';
import Card from '@/components/Card';
import { API } from '@/api';
import NotesField from '../../NotesField';
import { updateNote as dispatchUpdateNote } from '@/store/slices/notesSlice';
import DeleteNoteDialog from '@/dialogs/DeleteNoteDialog';
import NoteBlockHeader from './NoteBlockHeader';
import CommentsSection from './CommentsSection';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  useDateFormatPreference,
  useDispatch,
  useNote,
} from '@/store/useStore';
import { classes, styles } from './utils';

export type NoteBlockProps = {
  noteID: number;
};

export default function NoteBlock({ noteID }: NoteBlockProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const dateFormat = useDateFormatPreference();
  const note = useNote(noteID);
  const [content, setContent] = useState('');
  const [isEditing, toggleEditing] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);
  const [isDeleteWarningShown, toggleDeleteWarning] = useToggle(false);

  const handleEditNote = () => {
    setContent(note!.content);
    toggleEditing(true);
  };

  const handleCancelEdit = () => {
    toggleEditing(false);
    setContent('');
  };

  const handleShowDeleteWarning = () => {
    toggleDeleteWarning();
  };

  const handleSave = async (update: { content: string }) => {
    try {
      toggleSaving(true);
      const updatedNote = await API.Notes.updateNote(noteID, {
        content: update.content,
      });
      dispatch(dispatchUpdateNote(updatedNote));
      toggleSaving(false);
      toggleEditing(false);
      setContent(content);
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving note');
    }
  };

  const renderDeleteDialog = () => {
    if (!isDeleteWarningShown) return null;
    return (
      <DeleteNoteDialog noteID={noteID} onClose={() => toggleDeleteWarning()} />
    );
  };

  if (!note) return null;

  return (
    <Card sx={styles.card} className={classes.card}>
      {renderDeleteDialog()}
      <div className={classes.contentWrapper}>
        <NoteBlockHeader
          note={note}
          dateFormat={dateFormat}
          onEdit={handleEditNote}
          onDelete={handleShowDeleteWarning}
        />
        <div className={classes.notesWrapper}>
          {isEditing ? (
            <NotesField
              isSaving={isSaving}
              name="content"
              defaultValue={content}
              onSave={handleSave}
              onCancel={handleCancelEdit}
            />
          ) : (
            <p>{note.content}</p>
          )}
        </div>
      </div>
      <CommentsSection note={note} />
    </Card>
  );
}
