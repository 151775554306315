import { API } from '@/api';

export const classes = {
  wrapper:
    'py-3 w-[14rem] flex items-center justify-center cursor-pointer bg-white shadow-card hover:shadow-lg rounded-lg transition-all text-grey-8',
  disabled: 'cursor-not-allowed hover:bg-grey-2',
  icon: 'text-grey-7 mr-2',
};

export async function createChecklist(name: string, items: string[]) {
  return API.Checklists.createChecklist({
    name,
    isTemplate: false,
    items: items.map((item) => ({ description: item })),
  });
}

export async function addChecklistToProject(
  projectID: number,
  checklistID: number
) {
  return API.UnisonProjects.addChecklistToUnisonProject(projectID, checklistID);
}
