import { buildUserOption } from '@/utils/filters';
import { DecoratedPracticeUser } from '@witmetrics/api-client';

export const classes = {
  filtersWrapper: 'flex',
  filtersList: 'w-[225px]',
  buttonsWrapper: 'px-4 py-4 flex justify-between items-center',
  resetButton: 'px-3',
  applyButton: 'px-6',
};

export const FILTER_OPTIONS = [
  { value: false, label: 'Open' },
  { value: true, label: 'Resolved' },
];

export const ALL_STATUSES = [true, false];

export function formatUserOption(practiceUser: DecoratedPracticeUser) {
  return buildUserOption(practiceUser.user);
}
