import { useEffect } from 'react';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { addUnisonProject as dispatchAddUnisonProject } from '@/store/slices/unisonProjectsSlice';
import { setUnisonProjectTasks as dispatchSetUnisonProjectTasks } from '@/store/slices/unisonProjectTasksSlice';
import { setUnisonProjectChecklists as dispatchSetUnisonProjectChecklists } from '@/store/slices/unisonProjectChecklistsSlice';
import { setUnisonProjectConversations as dispatchSetUnisonProjectConversations } from '@/store/slices/unisonProjectConversationsSlice';
import { setUnisonProjectNotes as dispatchSetUnisonProjectNotes } from '@/store/slices/unisonProjectNotesSlice';
import { setPracticeUsers as dispatchSetPracticeUsers } from '@/store/slices/userPracticesSlice';
import Overlay from '@/components/Overlay';
import ProjectInfoColumn from './ProjectInfoColumn';
import TabsColumn from './TabsColumn';
import ProjectDialog from '@/dialogs/ProjectDialog';
import DeleteProjectDialog from '@/dialogs/DeleteProjectDialog';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, fetchProjectData } from './utils';

export default function ProjectOverlay() {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { onApiError, activeProjectID, onClearActiveProject } = useAppState();
  const [isInfoCollapsed, toggleInfoCollapsed] = useToggle(false);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isProjectDialogShown, toggleProjectDialog] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);

  useEffect(() => {
    if (activeProjectID && activeAccount) fetchData();
  }, [activeProjectID, activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const results = await fetchProjectData(
        activeProjectID!,
        activeAccount!.accountID
      );
      dispatch(dispatchAddUnisonProject(results.unisonProject));
      dispatch(dispatchSetUnisonProjectTasks(results.tasks));
      dispatch(dispatchSetUnisonProjectChecklists(results.checklists));
      dispatch(dispatchSetUnisonProjectConversations(results.conversations));
      dispatch(dispatchSetUnisonProjectNotes(results.notes));
      dispatch(dispatchSetPracticeUsers(results.practiceUsers));
      toggleFetchingData(false);
    } catch (err) {
      onApiError(err, 'Error fetching project data', () => fetchData());
    }
  };

  if (!activeProjectID) return null;

  return (
    <Overlay className={classes.overlay} onClose={onClearActiveProject}>
      {isProjectDialogShown && (
        <ProjectDialog
          projectID={activeProjectID}
          onClose={() => toggleProjectDialog(false)}
        />
      )}
      {isDeleteDialogShown && (
        <DeleteProjectDialog
          projectID={activeProjectID}
          onDelete={onClearActiveProject}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
      <ProjectInfoColumn
        isFetchingData={isFetchingData}
        collapsed={isInfoCollapsed}
        onToggleCollapsed={toggleInfoCollapsed}
      />
      <TabsColumn
        isFetchingData={isFetchingData}
        collapsed={isInfoCollapsed}
        onToggleCollapsed={toggleInfoCollapsed}
        onEditProject={() => toggleProjectDialog(true)}
        onDeleteProject={() => toggleDeleteDialog(true)}
      />
    </Overlay>
  );
}
