import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export type EmptyMessageProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export default function EmptyMessage({
  className = '',
  ...props
}: EmptyMessageProps) {
  return <div className={twMerge(classes.message, className)} {...props} />;
}
