import { CSSProperties, MouseEventHandler, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Box from '@mui/material/Box';
import { classes, getMinutes, renderHour, styles } from './utils';

export type HourBlockProps = {
  hour: number;
  style?: CSSProperties;
  showLabels?: boolean;
  offHours?: boolean;
  height?: number;
  onStartTask: (hour: number, minutes: number) => void;
};

export default function HourBlock({
  hour,
  style = {},
  showLabels = true,
  offHours = false,
  height = 120,
  onStartTask,
}: HourBlockProps) {
  const wrapperRef = useRef(null);

  const handleStartTask: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const point = (e.clientY - rect.y) / rect.height;
    onStartTask(hour, getMinutes(point));
  };

  return (
    <Box
      ref={wrapperRef}
      sx={style}
      className={twMerge(
        classes.wrapper,
        offHours ? classes.offHours : classes.onHours
      )}
      onDoubleClick={handleStartTask}>
      {showLabels && <label className={classes.hour}>{renderHour(hour)}</label>}
      <Box sx={styles.block(height)} />
    </Box>
  );
}
