import type { UndecoratedUser } from '@witmetrics/api-client';
import { renderUserName } from '@/utils/strings';
import { renderDateWithTime } from '@/utils/time';
import OptionsMenu from './OptionsMenu';
import { classes } from './utils';

export type BlockHeaderProps = {
  editable?: boolean;
  createdBy?: UndecoratedUser;
  createdAt: string;
  dateFormat?: string;
  onEdit: () => void;
  onDelete: () => void;
};

export default function BlockHeader({
  editable,
  createdBy,
  createdAt,
  dateFormat,
  onEdit,
  onDelete,
}: BlockHeaderProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.authorWrapper}>
        {/*{renderIcon()}*/}
        {createdBy && (
          <span className={classes.createdBy}>{renderUserName(createdBy)}</span>
        )}
        <span className={classes.createdAt}>
          {renderDateWithTime(createdAt, dateFormat)}
        </span>
      </div>
      <div>
        {editable && <OptionsMenu onEdit={onEdit} onDelete={onDelete} />}
      </div>
    </div>
  );
}
