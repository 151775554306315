import { twMerge } from 'tailwind-merge';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ChecklistItem from './ChecklistItem';
import NewChecklistItem from './NewChecklistItem';
import { useChecklist } from '@/store/useStore';
import { Container, type ContainerOptions } from 'react-smooth-dnd';
import { isMoved } from '@/utils/arrays';
import { buildItemsList, classes, styles } from './utils';

export type ChecklistProps = {
  isFetchingData?: boolean;
  draggable?: boolean;
  showComplete?: boolean;
  className?: string;
  checklistID: number;
  onReorder: (removedIndex: number, addedIndex: number) => void;
};

export default function Checklist({
  isFetchingData,
  draggable = false,
  showComplete = true,
  className,
  checklistID,
  onReorder,
}: ChecklistProps) {
  const checklist = useChecklist(checklistID);

  const handleDrop: ContainerOptions['onDrop'] = ({
    removedIndex,
    addedIndex,
  }) => {
    if (isMoved(removedIndex, addedIndex)) {
      onReorder(removedIndex!, addedIndex!);
    }
  };

  const renderLoading = () => {
    return [0, 0.2, 0.3].map((delay) => (
      <ListItem key={delay} sx={styles.skeletonItem}>
        <Skeleton
          className={classes.skeleton}
          variant="rectangular"
          animation="wave"
          sx={styles.skeleton(delay)}
        />
      </ListItem>
    ));
  };

  const renderItems = () => {
    const items = buildItemsList(checklist, showComplete);
    return items.map((item) => (
      <ChecklistItem draggable={draggable} key={item.id} item={item} />
    ));
  };

  const renderContent = () => {
    return isFetchingData ? renderLoading() : renderItems();
  };

  return (
    <List sx={styles.list} className={twMerge(classes.list, className)}>
      {draggable ? (
        //@ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93
        <Container
          dragClass="js-dragging-item"
          dropPlaceholder={{
            className: classes.dropPlaceholder,
          }}
          dragHandleSelector=".js-drag-handle"
          lockAxis="y"
          onDrop={handleDrop}>
          {renderContent()}
        </Container>
      ) : (
        renderContent()
      )}
      {!isFetchingData && <NewChecklistItem checklistID={checklistID} />}
    </List>
  );
}
