import type { ReactElement } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { classes, isActive, styles } from './utils';

export type SidebarButtonProps = {
  href: string;
  label: string;
  icon?: ReactElement;
  collapsed?: boolean;
  color?: string;
  bgColor?: string;
};

export default function SidebarButton({
  href,
  label,
  icon,
  collapsed,
  color,
  bgColor,
}: SidebarButtonProps) {
  const pathname = usePathname();
  const isButtonActive = isActive(href, pathname);
  const linkClasses = isButtonActive ? classes.active : classes.inactive;
  return (
    <Link
      href={href}
      style={isButtonActive ? styles.active(color, bgColor) : {}}
      className={`${classes.button} ${linkClasses}`}>
      {icon ? (
        <span
          style={styles.icon}
          className={`${classes.icon} ${collapsed ? '' : classes.iconOpen}`}>
          {icon}
        </span>
      ) : null}
      <span style={styles.label(collapsed)}>{label}</span>
    </Link>
  );
}
