export const INTERACTION_TYPES = {
  NOTE: {
    id: 1,
    name: 'NOTE',
    label: 'Note',
  },
  CALL: {
    id: 3,
    name: 'CALL',
    label: 'Phone Call',
  },
  CREATE_BOOKING: {
    id: 7,
    name: 'CREATE_BOOKING',
    label: 'Create Booking',
  },
  CREATE_TASK: {
    id: 12,
    name: 'CREATE_TASK',
    label: 'Create Task',
  },
  CREATE_TREATMENT_PLAN: {
    id: 13,
    name: 'CREATE_TREATMENT_PLAN',
    label: 'Create Treatment Plan',
  },
  LEAD_CREATION: {
    id: 8,
    name: 'LEAD_CREATION',
    label: 'Create New Lead',
  },
} as const;
