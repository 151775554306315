import { API } from '@/api';
import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  userInput: 'w-full my-4',
  name: 'w-full my-4',
  description: 'w-full mt-4 mb-8',
};

export function validateFields({ name }: { name: string }) {
  let invalidFields = [];
  if (isBlank(name)) invalidFields.push('name');
  return invalidFields;
}

export function updateChecklist(
  checklistID: number,
  data: { name: string; description: string }
) {
  return API.Checklists.updateChecklist(checklistID, data);
}
