import { API } from '@/api';
import { INTERACTION_TYPES } from '@/constants/interactionTypes';
import { Dictionary } from '@/types';
import { ParsedProperty } from '@/types/properties';
import {
  UndecoratedLead,
  UndecoratedUserProperty,
} from '@witmetrics/api-client';

export const classes = {
  dialog: 'w-[98vw] max-w-[1000px] min-w-[500px]',
  input: 'w-full py-3',
  section: 'w-full max-w-[500px] py-3',
  textField: 'w-full',
  selectDropdown: 'w-full',
  body: 'pt-0 pb-6 w-full min-h-0 h-[70vh] overflow-auto',
  withLead: 'max-h-[450px]',
  withoutLead: 'max-h-[1500px]',
};

export function formatSelectValue(label: string) {
  return { value: label, label };
}

export function buildInitialLeadData(
  lead: UndecoratedLead,
  userProperties: Dictionary<UndecoratedUserProperty>
) {
  const { firstname, lastname, email } = lead;
  const phone = userProperties.phone ? userProperties.phone.value : '';
  const enquiryStatus = formatSelectValue(
    userProperties.enquiryStatus
      ? userProperties.enquiryStatus.value
      : 'New enquiry'
  );
  return {
    firstname,
    lastname,
    email,
    phone,
    enquiryStatus,
  };
}

export function validateFields({
  firstname,
  email,
}: {
  firstname: string;
  email: string;
}) {
  let invalidFields = [];
  if (firstname.trim().length === 0) invalidFields.push(firstname);
  if (email.trim().length === 0) invalidFields.push(email);
  return invalidFields;
}

export function buildNewLeadProperties({
  practiceProperties,
  phone,
  enquiryStatus,
}: {
  practiceProperties: Dictionary<ParsedProperty>;
  phone: string;
  enquiryStatus: {
    value: string;
  };
}) {
  const getID = (key: keyof typeof practiceProperties) =>
    practiceProperties[key].id;
  let leadProperties = [];
  if (phone !== '') {
    leadProperties.push({ propertyID: getID('phone'), value: phone });
  }
  if (enquiryStatus.value !== '') {
    leadProperties.push({
      propertyID: getID('enquiryStatus'),
      value: enquiryStatus.value,
    });
  }
  return leadProperties;
}

export async function createNoteInteraction(leadID: number, notes: string) {
  return API.Interactions.createNewInteraction({
    userID: leadID,
    interactionTypeID: INTERACTION_TYPES.NOTE.id,
    content: notes.trim(),
  });
}
