'use client';
import { type CSSProperties, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Tooltip from '@/components/Tooltip';
import { useToggle } from '@/hooks/useToggle';
import { renderDateOnlyTime } from '@/utils/time';
import { classes, getTimeOffset, styles } from './utils';

export type CurrentTimeProps = {
  className?: string;
  hourHeight: number;
  offsetTop: string;
  showDot?: boolean;
  style?: CSSProperties;
};

export default function CurrentTime({
  className = '',
  hourHeight,
  offsetTop,
  style = {},
  showDot,
  ...props
}: CurrentTimeProps) {
  const [offset, setOffset] = useState(getTimeOffset(hourHeight));
  const [isTooltipShown, toggleTooltip] = useToggle(false); // Toggling tooltip forces it to render the most current time

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset(getTimeOffset(hourHeight));
    }, 30000); // Every 30 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={twMerge(classes.wrapper, className)}
      style={{ ...styles.wrapper(offset, offsetTop), ...style }}
      {...props}>
      {showDot && (
        <Tooltip
          open={isTooltipShown}
          title={renderDateOnlyTime(new Date().toISOString())}
          onOpen={() => toggleTooltip(true)}
          onClose={() => toggleTooltip(false)}>
          <div className={classes.dot} />
        </Tooltip>
      )}
    </div>
  );
}
