export const classes = {
  wrapper:
    'rounded-lg bg-white flex items-center justify-between px-4 py-2 mb-2 shadow-card',
  detailsWrapper: 'flex items-center justify-start',
  iconWrapper:
    'rounded-lg bg-grey-1 border border-grey-3 flex flex-col items-center justify-center py-1 px-1 mr-4',
  icon: 'text-xl text-grey-6 select-none',
  name: 'text-base text-grey-8',
  removeIcon: 'text-grey-6 hover:text-grey-7 cursor-pointer transition-colors',
};
