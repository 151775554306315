import { ChangeEvent, forwardRef, useState } from 'react';
import {
  DecoratedUserPractice,
  UndecoratedPractice,
} from '@witmetrics/api-client';
import Dialog from '@mui/material/Dialog';
import InputBase from '@mui/material/InputBase';
import Slide, { SlideProps } from '@mui/material/Slide';
import PracticesList from './PracticesList';
import { API } from '@/api';
import { ACTIVE_ACCOUNTS } from '@/constants/activeAccounts';
import { setActiveAccount as dispatchSetActiveAccount } from '@/store/slices/activeAccountSlice';
import {
  useActiveAccount,
  useCurrentUser,
  useDispatch,
} from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { sortAlphabeticalByKey } from '@/utils/arrays';
import { classes, styles } from './utils';
import { isBlank } from '@/utils/strings';

const Transition = forwardRef<any, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export type AccountsDialogProps = {
  onClose: () => void;
};

export default function AccountsDialog({ onClose }: AccountsDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const activeAccount = useActiveAccount();
  const [search, setSearch] = useState('');

  const getPractices = () => {
    let practices: UndecoratedPractice[] = currentUser!.userPractices.map(
      (up: DecoratedUserPractice) => up.practice
    );
    if (!isBlank(search)) {
      practices = practices.filter((p) =>
        p.name.toLowerCase().includes(search.trim().toLowerCase())
      );
    }
    return sortAlphabeticalByKey<UndecoratedPractice>(practices, 'name');
  };

  const handleSearch = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
  };

  const handleSelect = async (practiceID: number) => {
    try {
      if (practiceID === activeAccount?.accountID) {
        return onClose();
      }
      const result = await API.Users.setActiveAccount(
        ACTIVE_ACCOUNTS.PRACTICE,
        practiceID
      );
      dispatch(dispatchSetActiveAccount(result.activeAccount));
      onClose();
    } catch (err) {
      onApiError(err, 'Error setting active account');
    }
  };

  return (
    <Dialog
      open
      keepMounted
      disableRestoreFocus
      maxWidth={false}
      TransitionComponent={Transition}
      sx={styles.dialog}
      PaperProps={{ className: classes.paper }}
      onClose={onClose}>
      <div className={classes.wrapper}>
        <InputBase
          autoFocus
          autoComplete="off"
          className={classes.inputBase}
          name="accounts-search"
          placeholder="Search for an account..."
          type="text"
          value={search}
          onChange={handleSearch}
        />
        <PracticesList
          className={classes.resultsList}
          practices={getPractices()}
          onSelect={handleSelect}
        />
      </div>
    </Dialog>
  );
}
