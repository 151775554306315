import { API } from '@/api';

export const classes = {
  card: 'px-0 py-0 flex flex-col h-full w-[350px] overflow-y-auto mr-6 shrink-0',
  list: 'w-full py-0',
};

export function updateItemOrder(checklistID: number, itemOrder: number[]) {
  return API.Checklists.updateChecklist(checklistID, { itemOrder });
}

export function deleteChecklist(checklistID: number) {
  return API.Checklists.removeChecklist(checklistID);
}
