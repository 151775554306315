import { theme } from '@/utils/theme';
import { padNumber } from '@/utils/strings';

export const classes = {
  wrapper: 'flex hour-block',
  hour: 'relative px-3 text-base top-[-0.75rem] text-grey-8 font-normal',
  offHours: 'bg-grey-2',
  onHours: 'bg-white',
};

export const styles = {
  block: (height: number) => ({
    '&': {
      height: `${height}px`,
      width: '100%',
      borderTop: `1px solid ${theme.palette['grey-3'].main}`,
    },
  }),
};

export function renderHour(hour: number) {
  return `${padNumber(hour)}:00`;
}

export function getMinutes(point: number) {
  if (point >= 0.75) return 45;
  else if (point >= 0.5) return 30;
  else if (point >= 0.25) return 15;
  return 0;
}
