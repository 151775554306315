import { getTimeOfDayMs } from '@/utils/time';

export const classes = {
  wrapper: 'absolute w-full border-b-2 border-red-2',
  dot: 'h-3 w-3 rounded-full bg-red-2 absolute left-0 top-[-5px] cursor-pointer',
};

export const styles = {
  wrapper: (offset: number, offsetTop: string) => ({
    filter: 'drop-shadow(0px 2px 5px hsla(0,0%,20%,0.25))',
    top: `calc(${offset}px + ${offsetTop})`,
  }),
};

export function getTimeOffset(hourHeight: number) {
  const totalLength = 24 * 60 * 60 * 1000; // 24 hours to ms
  return 24 * hourHeight * (getTimeOfDayMs() / totalLength);
}
