import { useRef } from 'react';
import type {
  UndecoratedFile,
  UndecoratedFileCollection,
} from '@witmetrics/api-client';
import { useToggle } from '@/hooks/useToggle';
import { type Directory } from '@/utils/files';
import IconButton from '@/components/IconButton';
import AttachmentIcon from '@/icons/AttachmentIcon';
import AttachmentMenu from './AttachmentMenu';

export type AttachmentButtonProps = {
  projectID?: number;
  onAddFiles: (files: File[]) => void;
  onAddDirectory: (directory: Directory) => void;
  onAddProjectFiles: (files: UndecoratedFile[]) => void;
  onAddProjectFileCollections: (
    fileCollections: UndecoratedFileCollection[]
  ) => void;
};

export default function AttachmentButton({
  projectID,
  onAddFiles,
  onAddDirectory,
  onAddProjectFiles,
  onAddProjectFileCollections,
}: AttachmentButtonProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isProjectDialogOpen, toggleProjectDialog] = useToggle(false);

  const handleAddFiles = (files: File[]) => {
    toggleMenu(false);
    onAddFiles(files);
  };

  const handleAddDirectory = (directory: Directory) => {
    toggleMenu(false);
    onAddDirectory(directory);
  };

  const handleSelectFromProject = () => {
    toggleMenu(false);
    toggleProjectDialog(true);
  };

  const handleAddProjectFiles = (files: UndecoratedFile[]) => {
    toggleMenu(false);
    onAddProjectFiles(files);
  };

  const handleAddProjectFileCollections = (
    fileCollections: UndecoratedFileCollection[]
  ) => {
    toggleMenu(false);
    onAddProjectFileCollections(fileCollections);
  };

  return (
    <>
      <IconButton ref={menuToggleRef} onClick={() => toggleMenu()}>
        <AttachmentIcon />
      </IconButton>
      {isMenuOpen && (
        <AttachmentMenu
          open={isMenuOpen}
          hasProject={!!projectID}
          anchorTo={menuToggleRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onAddFiles={handleAddFiles}
          onAddDirectory={handleAddDirectory}
          onSelectFromProject={handleSelectFromProject}
          onClose={toggleMenu}
        />
      )}
    </>
  );
}
