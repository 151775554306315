import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { API } from '@/api';
import { useCurrentUser, useDispatch } from '@/store/useStore';
import { deleteConversationUser as dispatchDeleteConversationUser } from '@/store/slices/conversationUsersSlice';
import { deleteConversation as dispatchDeleteConversation } from '@/store/slices/conversationsSlice';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type LastMemberWarningDialogProps = {
  conversationID: number;
  onLeaveConversation?: (conversationID: number) => void;
  onClose: () => void;
};

export default function LastMemberWarningDialog({
  conversationID,
  onLeaveConversation,
  onClose,
}: LastMemberWarningDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleDelete = async () => {
    try {
      toggleDeleting(true);
      const userID = currentUser!.id;
      await API.Conversations.removeConversationUser(conversationID, userID);
      dispatch(dispatchDeleteConversationUser({ conversationID, userID }));
      dispatch(dispatchDeleteConversation({ conversationID }));
      toggleDeleting(false);
      showSuccessToaster('Conversation removed');
      if (onLeaveConversation) onLeaveConversation(conversationID);
      onClose();
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error leaving conversation');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Are you sure you want to leave?" onClose={onClose} />
      <DialogBody className={classes.body}>
        <p className={classes.paragraph}>
          You're the last person in this conversation.
        </p>
        <p className={classes.paragraph}>
          The conversation will be deleted if there's no remaining members.
        </p>
        <p className={classes.paragraph}>This action cannot be undone.</p>
      </DialogBody>
      <DefaultDialogActions
        disabled={isDeleting}
        pending={isDeleting}
        confirmLabel="Delete"
        pendingLabel="Deleting..."
        onCancel={onClose}
        onConfirm={handleDelete}
      />
    </Dialog>
  );
}
