import Menu, { type MenuProps } from '@/components/Menu';
import MenuOption from './MenuOption';
import AdminIcon from '@/icons/AdminIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import { classes } from './utils';

export type OptionsMenuProps = {
  open: boolean;
  isSelf: boolean;
  showAdminOption: boolean;
  isAdmin: boolean;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  onChangeAdminStatus: () => void;
  onRemove: () => void;
  onClose: () => void;
};

export default function OptionsMenu({
  open,
  isSelf,
  showAdminOption,
  isAdmin,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onChangeAdminStatus,
  onRemove,
  onClose,
}: OptionsMenuProps) {
  return (
    <Menu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      {showAdminOption && (
        <MenuOption
          label={isAdmin ? 'Remove as Admin' : 'Make Admin'}
          icon={<AdminIcon fontSize="small" />}
          onClick={onChangeAdminStatus}
        />
      )}
      <MenuOption
        className={classes.deleteIcon}
        label={isSelf ? 'Leave conversation' : 'Remove member'}
        // @ts-ignore -- MuiIcon not recognizing custom palette
        icon={<DeleteIcon fontSize="small" color="red-2" />}
        onClick={onRemove}
      />
    </Menu>
  );
}
