import { isToday, renderDayOfWeek } from '@/utils/time';

export const classes = {
  wrapper: 'pl-6 pr-2 py-4 flex items-center justify-between',
  iconButton: 'mr-2',
  date: 'relative px-1 mx-3 text-xl text-grey-8 cursor-pointer border-b border-transparent hover:border-grey-5 transition-colors',
  dateMenu: 'mt-3',
  dateWrapper: 'flex items-center',
  closeButton: 'text-grey-7',
};

export function renderDay(date: string) {
  if (isToday(date)) return 'Today';
  else return renderDayOfWeek(date);
}
