import {
  isThisWeek,
  isToday,
  renderDateOnlyTime,
  renderDateWithTime,
  renderDayOfWeek,
} from '@/utils/time';
import { UndecoratedUser } from '@witmetrics/api-client';
import { renderUserName } from '@/utils/strings';

export const classes = {
  wrapper: 'relative flex justify-start items-start w-full p-4',
  shortTop: 'pt-0',
  shortBottom: 'pb-1',
  selfWrapper: 'flex-row-reverse',
  avatarWrapper: 'flex justify-start items-start h-full pt-2',
  avatar: 'cursor-default',
  inactive: 'opacity-60 hover:opacity-100 transition-opacity',
  contentWrapper: 'flex flex-col',
  topRow: 'flex justify-between items-center w-full px-2 mb-0.5',
  selfContent: 'mr-3 max-w-[600px]',
  otherContent: 'ml-3',
  attachmentsBlock: 'flex flex-wrap mt-1 min-w-48',
  selfAttachments: 'justify-end',
  otherAttachments: 'justify-start',
  messageBlock: 'px-4 py-2 rounded-lg min-w-48 whitespace-pre-wrap',
  selfBlock: 'bg-blue-2 text-white',
  otherBlock: 'bg-grey-2 text-grey-8',
  name: 'text-sm text-grey-7 font-medium mr-2',
  sentAt: 'text-sm text-grey-6',
  hidden: 'opacity-0',
  attachment:
    'border border-blue-2 rounded-lg px-2 py-1 mr-1 mb-1 text-blue-2 text-sm select-none cursor-pointer',
};

export function getSentAtString(sentAt: string, dateFormat?: string) {
  if (isToday(sentAt)) {
    return renderDateOnlyTime(sentAt);
  } else if (isThisWeek(sentAt)) {
    return `${renderDayOfWeek(sentAt)}, ${renderDateOnlyTime(sentAt)}`;
  } else {
    return renderDateWithTime(sentAt, dateFormat);
  }
}

export function getNameString(user: UndecoratedUser, isActiveMember: boolean) {
  const name = renderUserName(user, true, true);
  return isActiveMember ? name : `[removed] ${name}`;
}
