import { theme } from '@/utils/theme';

export const classes = {
  wrapper: 'pl-4 px-7 mt-1 mb-3 w-full',
  closeIcon: 'cursor-pointer mb-1',
};

export const styles = {
  input: {
    '&': {
      width: '100%',
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette['grey-6'].main,
      opacity: 1,
    },
    '& .MuiInputBase-root:before': {
      border: '0px solid transparent',
    },
    '& .MuiInputBase-root': {
      paddingBottom: '0.25rem',
    },
    '& .MuiInputBase-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.palette['purple-2'].main,
      },
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: `1px solid ${theme.palette['grey-5'].main}`,
    },
    '& .MuiInputBase-root.Mui-focused:after': {
      borderBottom: `2px solid ${theme.palette['purple-2'].main}`,
    },
  },
};
