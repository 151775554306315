import type { DecoratedChecklist } from '@witmetrics/api-client';
import { isBlank } from '@/utils/strings';

export const classes = {
  wrapper: 'p-4 flex flex-col w-[300px] max-w-[98vw]',
  titleWrapper: 'text-center mb-6',
  title: 'font-medium text-base text-grey-8',
  templateDropdown: 'my-8',
};

export function searchTemplates(
  searchQuery: string,
  templates: DecoratedChecklist[]
) {
  const search = searchQuery.toLowerCase().trim();
  if (isBlank(search)) return templates;
  return templates.filter(({ name, description }) => {
    return `${name} ${description}`.toLowerCase().includes(search);
  });
}

export function formatTemplateOptions(templates: DecoratedChecklist[]) {
  return templates.map(({ id, name, description }) => ({
    value: id,
    label: name,
    description,
  }));
}
