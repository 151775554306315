import { useRef } from 'react';
import OptionsIcon from '@/icons/OptionsIcon';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type OptionsMenuProps = {
  onEdit: () => void;
  onDelete: () => void;
};

export default function OptionsMenu({ onEdit, onDelete }: OptionsMenuProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleEdit = () => {
    toggleMenu();
    onEdit();
  };

  const handleDelete = () => {
    toggleMenu();
    onDelete();
  };
  return (
    <div className={classes.optionsWrapper}>
      <OptionsIcon
        ref={menuToggleRef}
        className={classes.optionsIcon}
        // @ts-ignore
        color="grey-6"
        onClick={() => toggleMenu()}
      />
      <DefaultOptionsMenu
        open={isMenuOpen}
        anchorTo={menuToggleRef.current}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onClose={() => toggleMenu()}
      />
    </div>
  );
}
