import type { UpdateChecklistItemRequest } from '@witmetrics/api-client';
import { CheckBoxProps } from '@/components/CheckBox';
import { API } from '@/api';

export type CheckBoxChangeEvent = CheckBoxProps<'isComplete'>['onChange'];

export const classes = {
  listItem: 'js-drag-handle cursor-pointer active:cursor-grabbing',
  content:
    'js-draggable grow flex items-center justify-start mx-3 my-2 py-3 pl-4 hover:bg-grey-2 rounded-lg',
  checkboxWrapper: 'pr-2',
  contentWrapper: 'flex items-center justify-between grow pr-3',
  topRow: 'flex justify-between w-full grow',
  description: 'text-grey-8 mr-3 hover:underline cursor-pointer select-none',
  optionsButton:
    'options-button transition-all cursor-pointer text-grey-6 hover:text-grey-7',
  complete: 'text-grey-5 line-through',
  itemEditor: 'w-full',
};

export const styles = {
  listItem: {
    '&': {
      padding: 0,
      alignItems: 'flex-start',
    },
    '& .options-button': {
      opacity: 0,
    },
    '&:hover .options-button': {
      opacity: 1,
    },
  },
};

export function updateItem(itemID: number, data: UpdateChecklistItemRequest) {
  return API.Checklists.updateChecklistItem(itemID, data);
}

export function removeItem(itemID: number) {
  return API.Checklists.removeChecklistItem(itemID);
}
