import {
  type UndecoratedFile,
  type UndecoratedFileCollection,
} from '@witmetrics/api-client';
import { type Directory, uploadFileToFileCollection } from '@/utils/files';
import { API } from '@/api';
import { renderDateWithTime } from '@/utils/time';

export const classes = {
  wrapper: 'rounded-lg bg-grey-2 mx-4 px-4 pt-4 pb-2',
  textarea: 'resize-none w-full bg-transparent pb-2 outline-none',
  attachmentsWrapper: 'pb-1 mb-3 min-h-0 max-h-[250px] overflow-y-auto px-2',
  controlsWrapper:
    'flex justify-between items-center pt-2 border-t border-grey-3',
};

export const ATTACHMENT_TYPES = {
  NEW_FILE: 'NEW_FILE',
  NEW_DIRECTORY: 'NEW_DIRECTORY',
  PROJECT_FILE: 'PROJECT_FILE',
  PROJECT_FILE_COLLECTION: 'PROJECT_FILE_COLLECTION',
} as const;

export type NewFile = {
  uuid: string;
  type: typeof ATTACHMENT_TYPES.NEW_FILE;
  file: File;
};

export type NewDirectory = {
  uuid: string;
  type: typeof ATTACHMENT_TYPES.NEW_DIRECTORY;
  directory: Directory;
};

export type ProjectFile = {
  uuid: string;
  type: typeof ATTACHMENT_TYPES.PROJECT_FILE;
  file: UndecoratedFile;
};

export type ProjectFileCollection = {
  uuid: string;
  type: typeof ATTACHMENT_TYPES.PROJECT_FILE_COLLECTION;
  fileCollection: UndecoratedFileCollection;
};

export type MessageAttachment =
  | NewFile
  | NewDirectory
  | ProjectFile
  | ProjectFileCollection;

export async function sendMessage(
  conversationID: number,
  content: string,
  fileCollectionIDs: number[] = []
) {
  return API.Conversations.createConversationMessage(conversationID, {
    content,
    fileCollectionIDs,
    fileIDs: [],
    sentAt: new Date().toISOString(),
  });
}

function splitAttachments(attachments: MessageAttachment[]) {
  return {
    newFiles: attachments.filter(
      (a) => a.type === ATTACHMENT_TYPES.NEW_FILE
    ) as NewFile[],
    newDirectories: attachments.filter(
      (a) => a.type === ATTACHMENT_TYPES.NEW_DIRECTORY
    ) as NewDirectory[],
  };
}

async function fetchParentFileCollection(projectID: number) {
  const collection =
    await API.UnisonProjects.fetchUnisonProjectFileCollection(projectID);
  return {
    practiceID: collection.fileCollection.practiceID,
    parentFileCollectionID: collection.fileCollection.id,
  };
}

function createAttachmentCollection(
  practiceID: number,
  parentFileCollectionID: number
) {
  return API.FileCollections.createNewFileCollection({
    practiceID,
    parentFileCollectionID,
    name: `Message - ${renderDateWithTime(new Date().toISOString())}`,
  });
}

export async function uploadNewAttachments(
  projectID: number,
  attachments: MessageAttachment[]
) {
  const { newFiles, newDirectories } = splitAttachments(attachments);
  // Use the project's top-level file collection
  const { practiceID, parentFileCollectionID } =
    await fetchParentFileCollection(projectID);
  // Create a file collection to hold all the new files/fileCollections
  const fileCollection = await createAttachmentCollection(
    practiceID,
    parentFileCollectionID
  );
  // Returns file returns { file, fileCollectionFile, fileVersion }
  const files = await Promise.all(
    newFiles.map((f) =>
      uploadFileToFileCollection(practiceID, fileCollection.id, f.file)
    )
  );
  const directoryResults = await Promise.all(
    newDirectories.flatMap((newDirectory) =>
      Promise.all(
        Object.keys(newDirectory.directory).flatMap((key) =>
          uploadDirectory(
            key,
            newDirectory.directory[key],
            practiceID,
            fileCollection.id
          )
        )
      )
    )
  );
  return fileCollection;
}

async function uploadDirectory(
  name: string,
  contents: File | Directory,
  practiceID: number,
  parentCollectionID: number
): Promise<ReturnType<typeof uploadFileToFileCollection>[]> {
  // TODO: Why does the return type have to be Promise<any>?
  if (contents instanceof File) {
    return [
      uploadFileToFileCollection(practiceID, parentCollectionID, contents),
    ];
  } else {
    const fileCollection = await createDirectoryFileCollection(
      name,
      contents,
      practiceID,
      parentCollectionID
    );
    const result = await Promise.all(
      Object.keys(contents).map((key) =>
        uploadDirectory(key, contents[key], practiceID, fileCollection.id)
      )
    );
    return result.flat();
  }
}

async function createDirectoryFileCollection(
  name: string,
  directory: Directory,
  practiceID: number,
  parentCollectionID: number
) {
  const newCollection = await API.FileCollections.createNewFileCollection({
    name,
    practiceID,
    parentFileCollectionID: parentCollectionID,
  });
  let childFiles = 0;
  let childFileCollections = 0;
  Object.keys(directory).forEach((key) => {
    if (directory[key] instanceof File) childFiles++;
    else childFileCollections++;
  });
  return {
    ...newCollection,
    childFiles,
    childFileCollections,
  };
}
