import { useRef } from 'react';
import Link from 'next/link';
import Menu, { MenuItem } from '@/components/Menu';
import SettingsIcon from '@/icons/SettingsIcon';
import SupportIcon from '@/icons/SupportIcon';
import LocationIcon from '@/icons/LocationIcon';
import LogoutIcon from '@/icons/LogoutIcon';
import ProfilePicture from '@/components/ProfilePicture';
import { useActiveAccount, useCurrentUser } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { DIALOGS } from '@/constants/dialogs';
import { useToggle } from '@/hooks/useToggle';
import { renderUserName } from '@/utils/strings';
import {
  ACCOUNT_SETTINGS_LINK,
  classes,
  PRACTICE_SETTINGS_LINK,
  SUPPORT_LINK,
} from './utils';

export type UserMenuProps = {
  onLogout: () => void;
};

export default function UserMenu({ onLogout }: UserMenuProps) {
  const currentUser = useCurrentUser();
  const activeAccount = useActiveAccount();
  const { onOpenDialog } = useAppState();
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  if (!currentUser) return null;

  return (
    <div>
      <div ref={menuToggleRef}>
        <div className={classes.wrapper}>
          <ProfilePicture user={currentUser} onClick={() => toggleMenu()} />
          <div className={classes.label}>
            <div>{renderUserName(currentUser)}</div>
            {activeAccount ? (
              <button
                className={classes.button}
                onClick={() => onOpenDialog(DIALOGS.ACCOUNTS)}>
                {activeAccount.account.name}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <Menu
        open={isMenuOpen}
        anchorTo={menuToggleRef.current}
        onClose={() => toggleMenu()}>
        <Link href={ACCOUNT_SETTINGS_LINK}>
          <MenuItem label="Profile settings" icon={<SettingsIcon />} />
        </Link>
        <Link
          href={`${PRACTICE_SETTINGS_LINK}?practiceID=${activeAccount!.accountID}`}>
          <MenuItem label="Practice settings" icon={<LocationIcon />} />
        </Link>
        <Link href={SUPPORT_LINK} target="_blank" onClick={() => toggleMenu()}>
          <MenuItem label="Help Center" icon={<SupportIcon />} />
        </Link>
        <MenuItem label="Logout" icon={<LogoutIcon />} onClick={onLogout} />
      </Menu>
    </div>
  );
}
