'use client';
import { useEffect, useState } from 'react';
import TextField from '@/components/TextField';
import Button from '@/components/Button';
import { useChecklistItem } from '@/store/useStore';
import type { FormSubmitEvent } from '@/types';
import { classes } from './utils';

export type ItemInputProps = {
  isSaving: boolean;
  itemID?: number;
  placeholder?: string;
  onSave: (value: string) => void;
  onCancel: () => void;
};

export default function ItemInput({
  isSaving,
  itemID,
  placeholder = 'Checklist item...',
  onSave,
  onCancel,
}: ItemInputProps) {
  const [value, setValue] = useState('');
  const item = useChecklistItem(itemID);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onCancel();
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (item) setValue(item.description);
  }, [item]);

  const handleSave: FormSubmitEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSave(value.trim());
    setValue('');
  };

  return (
    <form className={classes.wrapper} onSubmit={handleSave}>
      <TextField
        autoFocus
        autoComplete="off"
        name="item"
        label=""
        placeholder={placeholder}
        value={value}
        onChange={({ item }) => setValue(item)}
      />
      <div className={classes.buttonsWrapper}>
        <Button
          variant="text"
          className={classes.button}
          textClasses={classes.cancel}
          onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={isSaving || value.trim().length === 0}
          className={classes.button}
          onClick={handleSave}>
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
