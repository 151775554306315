import ProfilePicture from '@/components/ProfilePicture';
import { renderUserName } from '@/utils/strings';
import { UndecoratedUser } from '@witmetrics/api-client';
import { classes } from './utils';

export type AssignedToProps = {
  assignedTo: UndecoratedUser;
};

export default function AssignedTo({ assignedTo }: AssignedToProps) {
  return (
    <div className={classes.list}>
      <ProfilePicture
        className={classes.profilePicture}
        size={30}
        user={assignedTo}
      />
      <span className={classes.assignedTo}>{renderUserName(assignedTo)}</span>
    </div>
  );
}
