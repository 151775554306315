import { twMerge } from 'tailwind-merge';
import { classes, LOGO_SRC } from './utils';

export type HeaderLogoProps = {
  className?: string;
  src?: string | null;
};

export default function HeaderLogo({ className, src }: HeaderLogoProps) {
  return (
    <img
      alt="witMetrics logo"
      className={twMerge(classes.logo, className)}
      src={src || LOGO_SRC}
    />
  );
}
