import { type ReactNode } from 'react';
import { UndecoratedUser } from '@witmetrics/api-client';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { renderUserName } from '@/utils/strings';
import { classes, styles } from './utils';

export type ListItemProps = {
  user: UndecoratedUser;
  icon: ReactNode;
  onClick: () => void;
};

export default function ListItem({ user, icon, onClick }: ListItemProps) {
  return (
    <MuiListItem disablePadding>
      <ListItemButton className={classes.item} onClick={onClick}>
        <ListItemText className={classes.text} primary={renderUserName(user)} />
        <ListItemIcon sx={styles.icon}>{icon}</ListItemIcon>
      </ListItemButton>
    </MuiListItem>
  );
}
