import { twMerge } from 'tailwind-merge';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import ProjectHeader from '../ProjectHeader';
import Divider from '@/components/Divider';
import TimelineSection from './TimelineSection';
import { useAppState } from '@/providers/AppStateProvider';
import { useUnisonProject } from '@/store/useStore';
import { classes } from './utils';

export type ProjectInfoColumnProps = {
  isFetchingData: boolean;
  collapsed: boolean;
  onToggleCollapsed: () => void;
};

export default function ProjectInfoColumn({
  isFetchingData,
  collapsed,
  onToggleCollapsed,
}: ProjectInfoColumnProps) {
  const { activeProjectID } = useAppState();
  const project = useUnisonProject(activeProjectID);

  if (isFetchingData) {
    return (
      <div className={twMerge(classes.wrapper, classes.expanded)}>
        <div className={classes.loading}>
          Fetching project
          <LoopingEllipsis />
        </div>
      </div>
    );
  }

  if (!project) return null;

  return (
    <div
      className={twMerge(
        classes.wrapper,
        collapsed ? classes.collapsed : classes.expanded
      )}>
      <div className={classes.content}>
        <ProjectHeader
          collapsed={collapsed}
          onToggleCollapsed={onToggleCollapsed}
        />
        {!collapsed && (
          <>
            <Divider className={classes.divider} />
            <TimelineSection />
          </>
        )}
      </div>
    </div>
  );
}
