import type { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { twMerge } from 'tailwind-merge';
import { setDefaultOptions } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import TimePicker from '@/components/TimePicker';
import { classes, styles } from './utils';
import { buildHoursArray, buildMinutesArray } from '@/utils/time';
import { WeekStartsOn } from '@/types/dates';

export type DateTimePopoverProps<N extends string> = {
  open: boolean;
  closeOnSelect?: boolean;
  disablePast?: boolean;
  showTime?: boolean;
  weekStartsOn?: WeekStartsOn;
  className?: string;
  name: N;
  value?: Date;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MuiMenuProps['anchorOrigin'];
  transformOrigin?: MuiMenuProps['transformOrigin'];
  onChange: (update: Record<N, Date>) => void;
  onClose: () => void;
};

export default function DateTimePopover<N extends string>({
  open,
  closeOnSelect = false,
  disablePast = true,
  showTime = true,
  weekStartsOn = 0,
  className = '',
  name,
  value = new Date(),
  anchorTo,
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  transformOrigin = { horizontal: 'right', vertical: 'top' },
  onChange,
  onClose,
}: DateTimePopoverProps<N>) {
  setDefaultOptions({ weekStartsOn });

  const handleSelectDate = (date: Date) => {
    let time = new Date(value.toISOString());
    time.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    onChange({ [name]: time } as Record<N, Date>);
    if (closeOnSelect) onClose();
  };

  const handleSelectTime = (update: { hours?: number; minutes?: number }) => {
    let time = new Date(value.toISOString());
    if (update.hasOwnProperty('hours')) time.setHours(update.hours!);
    if (update.hasOwnProperty('minutes')) time.setMinutes(update.minutes!);
    onChange({ [name]: time } as Record<N, Date>);
    if (closeOnSelect) onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      <Paper className={twMerge(classes.wrapper, className)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            showDaysOutsideCurrentMonth
            disablePast={disablePast}
            sx={styles.calendar}
            value={value}
            onChange={handleSelectDate}
          />
        </LocalizationProvider>
        {showTime && (
          <TimePicker
            hours={value.getHours()}
            minutes={value.getMinutes()}
            hoursOptions={buildHoursArray(true)}
            minutesOptions={buildMinutesArray(5)}
            onChange={handleSelectTime}
          />
        )}
      </Paper>
    </Popover>
  );
}
