import { API } from '@/api';

export const classes = {
  section: 'flex-1 min-h-0 overflow-y-auto',
  message: 'mt-4 text-center text-grey-6 text-base',
};

export async function createProjectNote(projectID: number, content: string) {
  const note = await API.Notes.createNewNote({ content });
  return API.UnisonProjects.addNoteToUnisonProject(projectID, note.id);
}
