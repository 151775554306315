import { API } from '@/api';
import { NoteComment } from '@witmetrics/api-client';
import { isJSON } from '@/utils/strings';

export const classes = {
  notesField: 'border border-grey-3',
  comment: 'flex flex-col py-2 px-2 items-start',
  content: 'text-sm text-grey-8 px-2 whitespace-pre-wrap',
};

export const styles = {
  comment: {
    '& .options-wrapper': {
      opacity: '0',
      transition: 'opacity 0.1s',
    },
    '&:hover .options-wrapper': {
      opacity: '1',
    },
  },
};

export async function updateNoteComment(commentID: number, message: string) {
  return API.Notes.updateNoteComment(commentID, { message });
}

export function parseContent({ content }: NoteComment): string {
  // Comment could either be a plain string *or* a JSON object depending on its type
  if (isJSON(content)) {
    const parsed = JSON.parse(content);
    return parsed.message || parsed;
  } else return content;
}
