import { MouseEvent, useState } from 'react';
import { useAppState } from '@/providers/AppStateProvider';
import { useActiveAccount } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { API } from '@/api';
import TextField from '@/components/TextField';
import { classes, isDisabled, validateFields } from './utils';

type LoginDialogProps = {
  onClose: () => void;
};

export default function LoginDialog({ onClose }: LoginDialogProps) {
  const { onApiError, onLogin } = useAppState();
  const activeAccount = useActiveAccount();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, toggleLoggingIn] = useToggle(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const handleLogin = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      toggleLoggingIn(true);
      const invalid = validateFields(email, password);
      if (invalid.length > 0) return setInvalidFields(invalid);
      const { accountType, accountID } = activeAccount!;
      await API.Users.login(email, password);
      await API.Users.setActiveAccount(accountType, accountID);
      toggleLoggingIn(false);
      onLogin();
    } catch (err) {
      toggleLoggingIn(false);
      onApiError(err, 'Error logging in');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle
        title="Log in"
        subtitle="Your session is expired. Please re-authenticate to continue."
        onClose={onClose}
      />
      <DialogBody className={classes.body}>
        <form className={classes.form}>
          <TextField
            error={invalidFields.includes('email')}
            className={classes.email}
            name="email"
            label="Email"
            value={email}
            onChange={({ email }) => setEmail(email)}
          />
          <TextField
            error={invalidFields.includes('password')}
            className={classes.password}
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={({ password }) => setPassword(password)}
          />
        </form>
      </DialogBody>
      <DefaultDialogActions
        disabled={isDisabled(email, password)}
        pending={isLoggingIn}
        confirmLabel="Log in"
        pendingLabel="Logging in..."
        onCancel={onClose}
        onConfirm={handleLogin}
      />
    </Dialog>
  );
}
