import type { DecoratedChecklist } from '@witmetrics/api-client';
import { useRef } from 'react';
import { useDispatch } from '@/store/useStore';
import { twMerge } from 'tailwind-merge';
import { addChecklist as dispatchAddChecklist } from '@/store/slices/checklistsSlice';
import { addUnisonProjectChecklist as dispatchAddProjectChecklist } from '@/store/slices/unisonProjectChecklistsSlice';
import AddIcon from '@/icons/AddIcon';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import NewChecklistMenu from './NewChecklistMenu';
import { showSuccessToaster } from '@/utils/toasters';
import { mapToKey } from '@/utils/arrays';
import { addChecklistToProject, classes, createChecklist } from './utils';

export type NewChecklistProps = {
  projectID: number;
  checklistTemplates: DecoratedChecklist[];
};

export default function NewChecklist({
  projectID,
  checklistTemplates,
}: NewChecklistProps) {
  const menuToggleRef = useRef(null);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [isCreatingChecklist, toggleCreatingChecklist] = useToggle(false);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleCreateChecklist = async (name: string, templateID?: number) => {
    try {
      if (isCreatingChecklist) return;
      toggleCreatingChecklist(true);
      let items: string[] = [];
      if (templateID) {
        const template = checklistTemplates.find((t) => t.id === templateID)!;
        items = mapToKey(template.items, 'description');
      }
      const checklist = await createChecklist(name, items);
      const projectChecklist = await addChecklistToProject(
        projectID,
        checklist.id
      );
      dispatch(dispatchAddChecklist(checklist));
      dispatch(dispatchAddProjectChecklist(projectChecklist));
      toggleCreatingChecklist(false);
      showSuccessToaster('Checklist created');
    } catch (err) {
      toggleCreatingChecklist(false);
      onApiError(err, 'Error creating checklist');
    }
  };

  return (
    <>
      <button
        ref={menuToggleRef}
        className={twMerge(
          classes.wrapper,
          isCreatingChecklist && classes.disabled
        )}
        onClick={() => toggleMenu()}>
        <AddIcon className={classes.icon} />
        <span>
          {isCreatingChecklist ? 'Adding checklist...' : 'New checklist'}
        </span>
      </button>
      {isMenuOpen && (
        <NewChecklistMenu
          open
          anchorTo={menuToggleRef.current}
          checklistTemplates={checklistTemplates}
          onSave={handleCreateChecklist}
          onClose={() => toggleMenu(false)}
        />
      )}
    </>
  );
}
