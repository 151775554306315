import { useRef } from 'react';
import IconButton from '@/components/IconButton';
import ArrowLeftIcon from '@/icons/ArrowLeftIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import CloseIcon from '@/icons/CloseIcon';
import DateTimePopover from '@/components/DateTimePopover';
import {
  useDateFormatPreference,
  useWeekStartsOnPreference,
} from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { renderDayOfWeek, renderTextDate } from '@/utils/time';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type HeaderProps = {
  onClose: () => void;
};

export default function Header({ onClose }: HeaderProps) {
  const dateFormat = useDateFormatPreference();
  const weekStartsOn = useWeekStartsOnPreference();
  const { activeCalendarDate, onOpenAgenda } = useAppState();
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleIncrement = (increment: number) => {
    let date = new Date(activeCalendarDate!);
    date.setDate(date.getDate() + increment);
    onOpenAgenda(date.toISOString());
  };

  const handleSelectDate = ({ date }: { date: Date }) =>
    onOpenAgenda(date.toISOString());

  return (
    <div className={classes.wrapper}>
      <div>
        {activeCalendarDate && (
          <div className={classes.dateWrapper}>
            <div>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleIncrement(-1)}>
                <ArrowLeftIcon />
              </IconButton>
              <IconButton onClick={() => handleIncrement(1)}>
                <ArrowRightIcon />
              </IconButton>
            </div>
            <h1
              ref={menuToggleRef}
              className={classes.date}
              onClick={() => toggleMenu()}>
              {`${renderDayOfWeek(activeCalendarDate, false)}, ${renderTextDate(
                activeCalendarDate,
                dateFormat
              )}`}
            </h1>
            {isMenuOpen && (
              <DateTimePopover
                open
                closeOnSelect
                disablePast={false}
                showTime={false}
                className={classes.dateMenu}
                name="date"
                value={new Date(activeCalendarDate)}
                weekStartsOn={weekStartsOn}
                anchorTo={menuToggleRef.current}
                onChange={handleSelectDate}
                onClose={() => toggleMenu(false)}
              />
            )}
          </div>
        )}
      </div>
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon className={classes.closeButton} />
      </IconButton>
    </div>
  );
}
