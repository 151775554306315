import { type ChangeEvent, forwardRef, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import InputBase from '@mui/material/InputBase';
import Slide, { type SlideProps } from '@mui/material/Slide';
import ResultsList from './ResultsList';
import { useAppState } from '@/providers/AppStateProvider';
import { classes, fetchMatchingProjects, type SearchResults } from './utils';

export type SearchDialogProps = {
  onClose: () => void;
};

const Transition = forwardRef<any, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SearchDialog({ onClose }: SearchDialogProps) {
  const timestampRef = useRef(Date.now());
  const { onApiError, onSelectProject } = useAppState();
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState<SearchResults>([]);

  const handleSelect = (projectID: number) => {
    onSelectProject(projectID);
    onClose();
  };

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    const query = newSearch.toLowerCase().trim();
    let searchSuggestions: SearchResults = [];
    setSearch(newSearch);
    if (query.length > 0) {
      try {
        const results = await fetchMatchingProjects(query);
        // Prevent overwriting newer responses with slower previous ones
        if (results.timestamp > timestampRef.current) {
          searchSuggestions = results.unisonProjects;
        } else {
          searchSuggestions = suggestions;
        }
        timestampRef.current = results.timestamp;
      } catch (err) {
        onApiError(err, 'Error finding projects');
      }
    }
    setSuggestions(searchSuggestions);
  };

  return (
    <Dialog
      open
      keepMounted
      disableRestoreFocus
      maxWidth={false}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
      onClose={onClose}>
      <div className={classes.wrapper}>
        <InputBase
          autoFocus
          autoComplete="off"
          className={classes.inputBase}
          name="projects-search"
          placeholder="Search for projects by lead, name, or description..."
          type="text"
          value={search}
          onChange={handleSearch}
        />
        {suggestions?.length > 0 ? (
          <ResultsList
            className={classes.resultsList}
            results={suggestions}
            onSelect={handleSelect}
          />
        ) : null}
      </div>
    </Dialog>
  );
}
