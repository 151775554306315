export const classes = {
  placeholder:
    'text-left bg-grey-2 py-0 px-3 rounded-lg w-full text-sm text-grey-7 hover:text-grey-8 hover:bg-grey-3 hover:py-2 cursor-text ease-in-out duration-100',
};

export const styles = {
  placeholder: {
    transitionProperty: 'color, background-color, padding',
  },
};
