import type { ChangeEvent, CSSProperties, KeyboardEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import InputAdornment from '@mui/material/InputAdornment';
import {
  default as MuiTextField,
  type TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import CloseIcon from '@/icons/CloseIcon';
import { classes, styles } from './utils';

export interface UnderlineTextFieldProps
  extends Omit<Partial<MuiTextFieldProps<'standard'>>, 'onChange'> {
  showCloseIcon?: boolean;
  className?: string;
  style?: CSSProperties;
  inputClassName?: string;
  inputStyle?: CSSProperties;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

export default function UnderlineTextField({
  showCloseIcon,
  className = '',
  style,
  inputClassName = '',
  inputStyle = {},
  placeholder = 'Enter value...',
  value,
  onChange,
  ...props
}: UnderlineTextFieldProps) {
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(e.target.value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (!['Escape', 'Enter'].includes(e.key)) {
      e.stopPropagation();
    }
  };

  return (
    <div style={style} className={twMerge(classes.wrapper, className)}>
      <MuiTextField
        autoComplete="off"
        className={inputClassName}
        sx={{ ...styles.input, ...inputStyle }}
        variant="standard"
        placeholder={placeholder}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleSearch}
        InputProps={{
          endAdornment: value.length > 0 && showCloseIcon && (
            <InputAdornment position="end">
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => onChange('')}
              />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </div>
  );
}
