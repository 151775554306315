import Button from '@/components/Button';
import { classes } from './utils';

export type MenuFooterProps = {
  onEdit: () => void;
  onDelete: () => void;
};

export default function MenuFooter({ onEdit, onDelete }: MenuFooterProps) {
  return (
    <div className={classes.wrapper}>
      <Button
        variant="text"
        className={classes.button}
        textClasses="text-red-2"
        onClick={onDelete}>
        Delete
      </Button>
      <Button variant="text" className={classes.button} onClick={onEdit}>
        Edit
      </Button>
    </div>
  );
}
