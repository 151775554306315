import { CSSProperties, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Box from '@mui/material/Box';
import HourBlock from './HourBlock';
import AgendaEvent from './AgendaEvent';
import CurrentTime from './CurrentTime';
import { useAppState } from '@/providers/AppStateProvider';
import { useUnisonProjectTasks } from '@/store/useStore';
import { filterTasks } from '@/utils/filters';
import { getRoundedDateTime } from '@/utils/time';
import {
  classes,
  getCurrentTasks,
  getHours,
  getOffsetHeight,
  isOffHour,
  sortEvents,
} from './utils';

export type AgendaProps = {
  className?: string;
  style?: CSSProperties;
  showLabels?: boolean;
  autoScroll?: boolean;
  showCurrentTime?: boolean;
  showCurrentTimeDot?: boolean;
  statusFilters?: boolean[];
  userFilters?: number[];
  hourHeight?: number;
  offsetTop?: string;
  date: string;
};

export default function Agenda({
  className = '',
  style = {},
  showLabels = true,
  autoScroll = true,
  showCurrentTime = true,
  showCurrentTimeDot = true,
  statusFilters = [],
  userFilters = [],
  hourHeight = 75,
  offsetTop = '3rem', // Account for anything changing the parent wrapper's sizing. 3rem = py-12
  date,
}: AgendaProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { onStartTask } = useAppState();
  const tasks = useUnisonProjectTasks();
  const currentTasks = getCurrentTasks(tasks, date);

  useEffect(() => {
    if (wrapperRef.current && autoScroll) {
      wrapperRef.current.scroll({ top: getOffsetHeight(hourHeight) });
    }
  }, []);

  const handleStartTask = (hour: number, minute: number) => {
    let taskDate = new Date(date);
    taskDate.setHours(hour, minute, 0);
    onStartTask(getRoundedDateTime(taskDate.toISOString()));
  };

  return (
    <Box
      ref={wrapperRef}
      className={twMerge(classes.wrapper, className)}
      sx={style}>
      {getHours().map((h) => (
        <HourBlock
          key={h}
          showLabels={showLabels}
          offHours={isOffHour(h, date)}
          hour={h}
          height={hourHeight}
          onStartTask={handleStartTask}
        />
      ))}
      {sortEvents(
        filterTasks(currentTasks, statusFilters, userFilters),
        showLabels ? 70 : 0
      ).map(({ unisonProjectTask, style }) => (
        <AgendaEvent
          key={unisonProjectTask.task.id}
          style={style}
          hourHeight={hourHeight}
          unisonProjectTask={unisonProjectTask}
          offsetTop={offsetTop}
        />
      ))}
      {showCurrentTime && (
        <CurrentTime
          showDot={showCurrentTimeDot}
          offsetTop={offsetTop}
          hourHeight={hourHeight}
        />
      )}
    </Box>
  );
}
