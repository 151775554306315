import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[40vw] max-w-[98vw]',
  body: 'pb-6 w-full',
  form: 'w-full mb-8',
  email: 'w-full',
  password: 'w-full',
};

export function validateFields(email: string, password: string) {
  let fields: string[] = [];
  if (isBlank(email)) fields.push('email');
  if (isBlank(password)) fields.push('password');
  return fields;
}

export function isDisabled(email: string, password: string) {
  return isBlank(email) || isBlank(password);
}
