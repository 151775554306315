import { theme } from '@/utils/theme';

export const classes = {
  wrapper:
    'flex items-center justify-center h-full w-full bg-default-gradient overflow-hidden bg-cover bg-no-repeat',
  card: 'py-12 px-4 md:px-16 max-w-[90vw]',
  text: 'text-sm md:text-base text-grey-8 font-medium',
};

export const styles = {
  card: {
    // Doing this in sx prevents a delay while Tailwind classes are still loading
    // @ts-ignore
    borderRadius: theme.shape.rounded['xl'],
  },
};
