import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useDispatch,
  useLead,
  useLeadUserProperties,
  usePracticeProperties,
} from '@/store/useStore';
import {
  addLead as dispatchAddLead,
  updateLead as dispatchUpdateLead,
} from '@/store/slices/leadsSlice';
import { addInteraction as dispatchAddInteraction } from '@/store/slices/interactionsSlice';
import SelectDropdown, {
  type SelectDropdownOption,
} from '@/components/SelectDropdown';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import FormBlock from '@/components/FormBlock';
import { API } from '@/api';
import { showErrorToaster, showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import type { DecoratedLead } from '@witmetrics/api-client';
import {
  buildInitialLeadData,
  buildNewLeadProperties,
  classes,
  createNoteInteraction,
  formatSelectValue,
  validateFields,
} from './utils';

export type LeadDialogProps = {
  leadID?: number;
  initialFirstname?: string;
  onSelect?: (lead: DecoratedLead) => void;
  onClose: () => void;
};

export default function LeadDialog({
  leadID,
  initialFirstname = '',
  onSelect,
  onClose,
}: LeadDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const practiceProperties = usePracticeProperties();
  const lead = useLead(leadID);
  const leadUserProperties = useLeadUserProperties(leadID);
  const activeAccount = useActiveAccount();
  const [firstname, setFirstname] = useState(initialFirstname);
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');
  const [enquiryStatus, setEnquiryStatus] = useState(
    formatSelectValue('New enquiry')
  );
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (lead) fillFields();
  }, [lead]);

  const fillFields = () => {
    const leadData = buildInitialLeadData(lead!, leadUserProperties);
    setFirstname(leadData.firstname);
    setLastname(leadData.lastname);
    setEmail(leadData.email);
    setPhone(leadData.phone);
    setEnquiryStatus(leadData.enquiryStatus);
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalidFields = validateFields({ firstname, email });
      if (invalidFields.length > 0) {
        toggleSaving(false);
        return setInvalidFields(invalidFields);
      }
      const emailData = await API.Users.validateEmail(email, lead?.id);
      if (!emailData.isValid) {
        showErrorToaster(emailData.message);
        toggleSaving(false);
        return setInvalidFields(['email']);
      }
      return lead ? handleUpdateLead() : handleCreateLead();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving lead', () => handleSave());
    }
  };

  const handleUpdateLead = async () => {
    try {
      const updatedLead = await API.Leads.updateLead(leadID!, {
        firstname,
        lastname,
        email,
        properties: practiceProperties.phone
          ? [{ propertyID: practiceProperties.phone.id, value: phone }]
          : [],
      });
      dispatch(dispatchUpdateLead(updatedLead));
      showSuccessToaster('Lead updated');
      toggleSaving(false);
      if (onSelect) onSelect(updatedLead);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error updating lead', () => handleUpdateLead());
    }
  };

  const getLeadProperties = () => {
    return buildNewLeadProperties({ practiceProperties, phone, enquiryStatus });
  };

  const handleCreateLead = async () => {
    try {
      if (!activeAccount) return;
      const leadProperties = getLeadProperties();
      const newLead = await API.Leads.createNewLead({
        firstname,
        lastname,
        email,
        properties: leadProperties,
        practiceID: activeAccount.accountID,
      });
      dispatch(dispatchAddLead(newLead));
      if (notes.trim().length > 0) {
        const interaction = await createNoteInteraction(newLead.id, notes);
        dispatch(dispatchAddInteraction(interaction));
      }
      showSuccessToaster('Lead created');
      toggleSaving(false);
      if (onSelect) onSelect(newLead);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error creating lead', () => handleCreateLead());
    }
  };

  const isSaveDisabled = () => {
    const invalidFields = validateFields({ firstname, email });
    return isSaving || invalidFields.length > 0;
  };

  const renderEnquiryFields = () => {
    if (!practiceProperties.enquiryStatus) return;
    return (
      <div className={classes.section}>
        <div className={classes.input}>
          <SelectDropdown<string, 'enquiryStatus'>
            className={classes.selectDropdown}
            name="enquiryStatus"
            label="Enquiry Status"
            value={enquiryStatus}
            options={
              practiceProperties.enquiryStatus
                .acceptedValues as SelectDropdownOption<string>[]
            }
            onChange={(update) => setEnquiryStatus(update.enquiryStatus)}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title={lead ? 'Edit Lead' : 'New Lead'} onClose={onClose} />
      <DialogBody
        className={`${classes.body} ${
          lead ? classes.withLead : classes.withoutLead
        }`}>
        <FormBlock
          title="Lead Info"
          subtitle="Basic information about the lead">
          <div className={classes.section}>
            <div className={classes.input}>
              <TextField
                autoFocus
                error={invalidFields.includes('firstname')}
                className={classes.textField}
                name="firstname"
                label="First Name*"
                value={firstname}
                onChange={(update) => setFirstname(update.firstname)}
              />
            </div>
            <div className={classes.input}>
              <TextField
                error={invalidFields.includes('lastname')}
                className={classes.textField}
                name="lastname"
                label="Last Name"
                value={lastname}
                onChange={(update) => setLastname(update.lastname)}
              />
            </div>
            <div className={classes.input}>
              <TextField
                autoFocus
                error={invalidFields.includes('email')}
                className={classes.textField}
                name="email"
                label="Email*"
                value={email}
                onChange={(update) => setEmail(update.email)}
              />
            </div>
            <div className={classes.input}>
              <TextField
                autoFocus
                error={invalidFields.includes('phone')}
                className={classes.textField}
                name="phone"
                label="Phone"
                value={phone}
                onChange={(update) => setPhone(update.phone)}
              />
            </div>
            {lead ? null : renderEnquiryFields()}
          </div>
        </FormBlock>
        {lead ? null : (
          <FormBlock
            title="Notes"
            subtitle="Notes help you remember extra information about the lead">
            <div className={classes.section}>
              <div className={classes.input}>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={5}
                  className={classes.textField}
                  name="notes"
                  label="Notes"
                  value={notes}
                  onChange={({ notes }) => setNotes(notes)}
                />
              </div>
            </div>
          </FormBlock>
        )}
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
