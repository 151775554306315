import {
  type DecoratedConversationUser,
  USER_CONVERSATION_STATUS_ID,
} from '@witmetrics/api-client';
import { renderUserName } from '@/utils/strings';
import { theme } from '@/utils/theme';

const { CREATOR, ADMIN, MEMBER } = USER_CONVERSATION_STATUS_ID;

export const classes = {
  listItem:
    'transition-all duration-100 py-2 px-4 my-1 rounded-lg flex justify-between items-center w-full text-base text-grey-8 font-medium',
  userInfo: 'flex items-center justify-start',
  nameWrapper: 'flex items-center ml-1',
  name: 'text-base text-grey-8 font-normal',
  avatarWrapper: 'pr-2',
  avatar: 'cursor-default',
  optionsIcon: 'icon-button cursor-pointer text-grey-6',
  optionsDisabled: 'cursor-not-allowed text-grey-5',
};

export const styles = {
  listItem: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&:hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '& .icon-button': {
      opacity: 0,
      transition: 'opacity 0.1s',
    },
    '&:hover .icon-button': {
      opacity: 1,
    },
  },
};

export function isAdmin(member: DecoratedConversationUser) {
  return [ADMIN, CREATOR].includes(member.userConversationStatusID);
}

export function toggleAdminStatus(member: DecoratedConversationUser) {
  return isAdmin(member) ? MEMBER : ADMIN;
}

export function renderName(member: DecoratedConversationUser) {
  return `${renderUserName(member.user)}${getStatusLabel(member)}`;
}

function getStatusLabel({
  userConversationStatusID,
}: DecoratedConversationUser) {
  if (userConversationStatusID === CREATOR) return ' (Creator)';
  else if (userConversationStatusID === ADMIN) return ' (Admin)';
  return '';
}
