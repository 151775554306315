import { useDispatch } from '@/store/useStore';
import { deleteNote as dispatchDeleteNote } from '@/store/slices/notesSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { API } from '@/api';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type DeleteNoteDialogProps = {
  noteID: number;
  onClose: () => void;
};

export default function DeleteNoteDialog({
  noteID,
  onClose,
}: DeleteNoteDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleDelete = async () => {
    try {
      toggleDeleting(true);
      await API.Notes.removeNote(noteID);
      dispatch(dispatchDeleteNote({ noteID }));
      showSuccessToaster('Note deleted');
      toggleDeleting(false);
      onClose();
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error deleting note', () => {
        handleDelete();
      });
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Delete note" onClose={onClose} />
      <DialogBody className={classes.body}>
        Are you sure you want to delete this note?
        <br />
        This action cannot be undone.
      </DialogBody>
      <DefaultDialogActions
        disabled={isDeleting}
        pending={isDeleting}
        confirmLabel="Delete"
        pendingLabel="Deleting..."
        onCancel={onClose}
        onConfirm={handleDelete}
      />
    </Dialog>
  );
}
