import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import TabsBlock from '@/components/TabsBlock';
import OverviewTab from '../OverviewTab';
import ChecklistsTab from '../ChecklistsTab';
import FilesTab from '../FilesTab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@/icons/CloseIcon';
import { useAppState } from '@/providers/AppStateProvider';
import { useUnisonProject } from '@/store/useStore';
import { usePath } from '@/hooks/usePath';
import OptionsIcon from '@/icons/OptionsIcon';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import { useToggle } from '@/hooks/useToggle';
import { classes, type ProjectOverlayTab } from './utils';

export type TabsColumnProps = {
  isFetchingData: boolean;
  collapsed: boolean;
  onToggleCollapsed: () => void;
  onEditProject: () => void;
  onDeleteProject: () => void;
};

export default function TabsColumn({
  isFetchingData,
  collapsed,
  onToggleCollapsed,
  onEditProject,
  onDeleteProject,
}: TabsColumnProps) {
  const menuToggleRef = useRef(null);
  const { searchParams, pushParams } = usePath();
  const { activeProjectID, onClearActiveProject } = useAppState();
  const project = useUnisonProject(activeProjectID);
  const [defaultTab, setDefaultTab] = useState<ProjectOverlayTab>('overview');
  const [isMenuOpen, toggleMenu] = useToggle(false);

  useEffect(() => {
    const tab = searchParams.get('projectOverlayTab');
    if (tab !== null) handleSelectTab(tab as ProjectOverlayTab);
  }, []);

  const handleSelectTab = (tab: ProjectOverlayTab) => {
    setDefaultTab(tab);
    pushParams({ projectOverlayTab: tab });
  };

  const handleEditProject = () => {
    toggleMenu();
    onEditProject();
  };

  const handleDeleteProject = () => {
    toggleMenu();
    onDeleteProject();
  };

  if (!project) return null;

  return (
    <div
      className={twMerge(
        classes.wrapper,
        collapsed ? classes.collapsed : classes.expanded
      )}>
      <div className={classes.buttonsWrapper}>
        {collapsed && <div className={classes.name}>{project.name}</div>}
        <IconButton
          ref={menuToggleRef}
          aria-label="options"
          onClick={() => toggleMenu()}>
          <OptionsIcon className={classes.icon} />
        </IconButton>
        <DefaultOptionsMenu
          open={isMenuOpen}
          anchorTo={menuToggleRef.current}
          onEdit={handleEditProject}
          onDelete={handleDeleteProject}
          onClose={() => toggleMenu()}
        />
        <IconButton aria-label="close" onClick={onClearActiveProject}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </div>
      <TabsBlock
        name="project-info"
        className={classes.tabsBlock}
        bodyClassName={classes.tabBody}
        defaultTab={defaultTab}
        onChange={(tab) => handleSelectTab(tab as ProjectOverlayTab)}>
        <OverviewTab
          isFetchingData={isFetchingData}
          collapsed={collapsed}
          onToggleCollapsed={onToggleCollapsed}
          name="overview"
          label="Overview"
        />
        <ChecklistsTab
          isFetchingData={isFetchingData}
          name="checklists"
          label="Checklists"
        />
        <FilesTab isFetchingData={isFetchingData} name="files" label="Files" />
      </TabsBlock>
    </div>
  );
}
