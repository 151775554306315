import { type MouseEventHandler, type ReactNode } from 'react';
import Box from '@mui/material/Box';
import TruncatedText from '@/components/TruncatedText';
import DownloadIcon from '@/icons/DownloadIcon';
import { classes, styles } from './utils';

export type AttachmentProps = {
  name: string;
  renderIcon: (className: string) => ReactNode;
  onOpen: () => void;
  onDownload?: () => void;
};

export default function Attachment({
  name,
  renderIcon,
  onOpen,
  onDownload,
}: AttachmentProps) {
  const handleOpen: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  };

  const handleDownload: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDownload!();
  };

  return (
    <Box
      sx={styles.wrapper}
      className={classes.wrapper}
      onDoubleClick={handleOpen}>
      <div className={classes.iconWrapper}>{renderIcon(classes.fileIcon)}</div>
      <TruncatedText
        className={classes.nameWrapper}
        textClassName={classes.name}>
        {name}
      </TruncatedText>
      {onDownload && (
        <div className={classes.downloadWrapper}>
          <DownloadIcon
            className={classes.downloadIcon}
            onClick={handleDownload}
          />
        </div>
      )}
    </Box>
  );
}
