export const classes = {
  item: 'w-auto mx-3 py-2 rounded-lg hover:bg-grey-2 bg-white',
  text: 'select-none text-grey-8',
};

export const styles = {
  icon: {
    '&': {
      minWidth: 'min-content',
    },
  },
};
