import { useEffect, useState } from 'react';
import { useActiveAccount } from '@/store/useStore';
import { type UndecoratedUser } from '@witmetrics/api-client';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import Divider from '@/components/Divider';
import MembersSelector from '@/components/MembersSelector';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { isEmpty, mapToID } from '@/utils/arrays';
import { classes, fetchConversationUsers } from './utils';

export type SelectConversationAdminsDialogProps = {
  conversationID: number;
  confirmLabel: string;
  onSave: (conversationID: number, adminIDs: number[]) => Promise<any>;
  onClose: () => void;
};

export default function SelectConversationAdminsDialog({
  conversationID,
  confirmLabel,
  onSave,
  onClose,
}: SelectConversationAdminsDialogProps) {
  const { onApiError } = useAppState();
  const activeAccount = useActiveAccount();
  const [newAdmins, setNewAdmins] = useState<UndecoratedUser[]>([]);
  const [conversationUsers, setConversationUsers] = useState<UndecoratedUser[]>(
    []
  );
  const [isFetchingData, toggleFetchingData] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (activeAccount?.accountID) handleFetchUsers();
  }, []);

  const handleFetchUsers = async () => {
    try {
      toggleFetchingData(true);
      const users = await fetchConversationUsers(conversationID);
      setConversationUsers(users);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching members', () => handleFetchUsers());
    }
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      await onSave(conversationID, mapToID(newAdmins));
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error updating Admins');
    }
  };

  const isSaveDisabled = () => {
    return isSaving || isEmpty(newAdmins);
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Select Admins" onClose={onClose} />
      <DialogBody className={classes.body}>
        <div className={classes.description}>
          <p className={classes.paragraph}>
            Conversations require at least one Admin.
          </p>
          <p className={classes.paragraph}>
            To leave this conversation, please set one or more current members
            as Admins.
          </p>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.selectorWrapper}>
          <MembersSelector
            isFetchingData={isFetchingData}
            options={conversationUsers}
            selected={newAdmins}
            onChange={(users) => setNewAdmins(users)}
          />
        </div>
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel={confirmLabel}
        pendingLabel="Updating..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
