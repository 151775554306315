export const classes = {
  card: 'flex flex-col my-2 px-0 py-0 rounded-xl',
  contentWrapper: 'content-wrapper px-2 pt-3 pb-4 flex flex-col',
  notesWrapper: 'text-sm text-grey-8 font-normal px-2 whitespace-pre-wrap',
};

export const styles = {
  card: {
    '& .options-wrapper': {
      opacity: '0',
      transition: 'opacity 0.1s',
    },
    '&:hover .content-wrapper .options-wrapper': {
      opacity: '1',
    },
  },
};
