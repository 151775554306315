import { useEffect } from 'react';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import Overlay from '@/components/Overlay';
import Divider from '@/components/Divider';
import Header from './Header';
import Agenda from '@/components/Agenda';
import { setUnisonProjectTasks as dispatchSetUnisonProjectTasks } from '@/store/slices/unisonProjectTasksSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, fetchTasks } from './utils';

export type AgendaOverlayProps = {
  onClose: () => void;
};

export default function AgendaOverlay({ onClose }: AgendaOverlayProps) {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { activeCalendarDate, onOpenAgenda, onApiError } = useAppState();
  const [isFetchingData, toggleFetchingData] = useToggle(true);

  useEffect(() => {
    if (activeCalendarDate) fetchData();
    else onOpenAgenda(new Date().toISOString());
  }, [activeCalendarDate]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const tasks = await fetchTasks(
        activeAccount!.accountID,
        activeCalendarDate!
      );
      dispatch(dispatchSetUnisonProjectTasks(tasks));
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching tasks', () => fetchData());
    }
  };

  return (
    <Overlay className={classes.overlay} onClose={onClose}>
      <div className={classes.wrapper}>
        <Header onClose={onClose} />
        <Divider />
        <Agenda
          showCurrentTime
          showCurrentTimeDot
          className={classes.agenda}
          hourHeight={75}
          date={activeCalendarDate!}
        />
      </div>
    </Overlay>
  );
}
